/**
 * 包含n个reducer函数：根据老的state和指定的action返回一个新的state
 */

import { combineReducers } from 'redux';

import { getIsMobile } from '@/common';

import {
  AUTH_SUCCESS,
  GET_AN_INSTANT_QUOTE_SUCCESS,
  LANGUAGE_CHANGE,
} from './action-types';

const initCommon = {
  isMobile: getIsMobile(),
};
function common(state = initCommon, action) {
  // const {type, data} = action;
  const { type } = action;
  switch (type) {
    default:
      return state;
  }
}

const initUser = {
  username: '',
  languageValue: '',
  languageName: '',
};
function user(state = initUser, action) {
  const { type, data } = action;
  switch (type) {
    case AUTH_SUCCESS:
      return Object.assign({}, state, data);
    case LANGUAGE_CHANGE:
      localStorage.setItem('wakool_language_value', data.languageValue);
      localStorage.setItem('wakool_language_name', data.languageName);
      return Object.assign({}, state, data);
    default:
      return state;
  }
}

const initAnInstantQuote = {
  data: {}
};
function anInstantQuote(state = initAnInstantQuote, action) {
  const { type, data } = action;
  switch (type) {
    case GET_AN_INSTANT_QUOTE_SUCCESS:
      return Object.assign({}, state, { data });
    default:
      return state;
  }
}

// 向外暴露的状态的结构: {app: {}, user: {}}
export default combineReducers({
  common,
  user,
  anInstantQuote,
})
