import React from 'react'
import {Button, message, Modal} from 'antd'
import './index.scss'
import './mobile.scss'
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import warehouseDetailImg from "@/assets/images/GetAnInstantCode/warehouseNew.png";
import {getFbaOrWalmartFile} from "@/api";
import { useState } from 'react';

const defaultProps = {
  isShow: false,
  onModalCancel: () => { },
};

export default function GetAnInstantCodeWarehouse(props) {
  const navigate = useNavigate()
  const options = Object.assign({}, defaultProps, props);
  const { t } = useTranslation()
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'getAnInstantCodeWarehouse-mobile' : 'getAnInstantCodeWarehouse'
  const [activeBtnType, setActiveBtnType] = useState('address6')
  const handleContactUsClick = () => {
    options.onModalCancel();
    navigate('contact');
  }
  const handleGoBackClick = () => {
    options.onModalCancel();
    options.showGetAnInstantCodeModal();
  }

  const handleQuoteBtnClick = (type,language) => {
    setActiveBtnType(type);
    getFbaOrWalmartFile(type,language).then(response => {
      if (response.data) {
        window.open(`/preview?language=${language}&url=${response.data}`);
      } else {
        message.error(t('instant_war_soon'))
      }
    });
  }
  return (
    <div>
      <Modal
        open={options.isShow}
        footer={null}
        maskClosable={false}
        onCancel={options.onModalCancel}
        className={rootClassName}>
        <div className={'get-an-instant-code-warehouse-container'}>
          <div className={'get-an-instant-code-warehouse-left-container'}>
            {/* <div className={'get-an-instant-code-warehouse-left-title'}>
              {t('instant_warehouse_quote')}
            </div>
            <div className={'get-an-instant-code-warehouse-left-des'}>
              {t('instant_warehouse_please')}
              <Button
                type="link"
                onClick={handleContactUsClick}
                className={'get-an-instant-code-warehouse-link'}>
                {t('instant_warehouse_contact')}
              </Button>
              {t('instant_warehouse_information')}
            </div>
            <div className={'get-an-instant-code-warehouse-left-tip'}>
              {t('instant_warehouse_thank')}
            </div> */}
            <div className={'get-an-instant-code-warehouse-left-img-container'}>
              <img src={warehouseDetailImg} alt="" />
            </div>
          </div>
          <div className='get-an-instant-code-warehouse-right-container'>
            <div
              className={'get-an-instant-code-warehouse-right-title'}>
               {t('warehouse_location')}
            </div>
            <div
              className={'get-an-instant-code-warehouse-right-title get-an-instant-code-warehouse-right-title-special'}>
               {t('select_location')}
            </div>
            <div className={'get-an-instant-code-warehouse-right-top-container'}>
              <Button
                onClick={() => handleQuoteBtnClick('address6',localStorage.getItem('wakool_language_value'))}
                className={activeBtnType === 'address6' ? 'get-an-instant-code-warehouse-right-btn-1 get-an-instant-code-warehouse-right-btn-1-active' : 'get-an-instant-code-warehouse-right-btn-1'}>
                {t('instant_warehouse_los_angeles')}
              </Button>
              <Button
                onClick={() => handleQuoteBtnClick('address7',localStorage.getItem('wakool_language_value'))}
                className={activeBtnType === 'address7' ? 'get-an-instant-code-warehouse-right-btn-1 get-an-instant-code-warehouse-right-btn-1-active' : 'get-an-instant-code-warehouse-right-btn-1'}>
                {t('instant_warehouse_new_jersey')}
              </Button>
              <Button
                onClick={() => handleQuoteBtnClick('address8',localStorage.getItem('wakool_language_value'))}
                className={activeBtnType === 'address8' ? 'get-an-instant-code-warehouse-right-btn-1 get-an-instant-code-warehouse-right-btn-1-active' : 'get-an-instant-code-warehouse-right-btn-1'}>
                {t('instant_warehouse_oak_land')}
              </Button>
              <Button
                onClick={() => handleQuoteBtnClick('address9',localStorage.getItem('wakool_language_value'))}
                className={activeBtnType === 'address9' ? 'get-an-instant-code-warehouse-right-btn-1 get-an-instant-code-warehouse-right-btn-1-active' : 'get-an-instant-code-warehouse-right-btn-1'}>
                {t('instant_warehouse_seattle')}
              </Button>
              <Button
                onClick={() => handleQuoteBtnClick('address10',localStorage.getItem('wakool_language_value'))}
                className={activeBtnType === 'address10' ? 'get-an-instant-code-warehouse-right-btn-1 get-an-instant-code-warehouse-right-btn-1-active' : 'get-an-instant-code-warehouse-right-btn-1'}>
                {t('instant_warehouse_houston')}
              </Button>
              <Button
                onClick={() => handleQuoteBtnClick('address11',localStorage.getItem('wakool_language_value'))}
                className={activeBtnType === 'address11' ? 'get-an-instant-code-warehouse-right-btn-1 get-an-instant-code-warehouse-right-btn-1-active' : 'get-an-instant-code-warehouse-right-btn-1'}>
                {t('instant_warehouse_baltimore')}
              </Button>
              <Button
                onClick={() => handleQuoteBtnClick('address12',localStorage.getItem('wakool_language_value'))}
                className={activeBtnType === 'address12' ? 'get-an-instant-code-warehouse-right-btn-1 get-an-instant-code-warehouse-right-btn-1-active' : 'get-an-instant-code-warehouse-right-btn-1'}>
                {t('instant_warehouse_savannah')}
              </Button>
              <Button
                onClick={() => handleQuoteBtnClick('address13',localStorage.getItem('wakool_language_value'))}
                className={activeBtnType === 'address13' ? 'get-an-instant-code-warehouse-right-btn-1 get-an-instant-code-warehouse-right-btn-1-active' : 'get-an-instant-code-warehouse-right-btn-1'}>
                {t('instant_warehouse_chicago')}
              </Button>
            </div>
            <Button
              className={'get-an-instant-code-warehouse-right-btn-2'}
              onClick={handleGoBackClick}
              ghost>
              {t('instant_fba_last_page')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
