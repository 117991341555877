const data = {
	"language_en": "English",
	"language_zh_cn": "中文(简体)",
	"language_zh_tw": "中文(繁體)",
	"home": "首頁",
	"quote": "詢價",
	"feedback": "反饋",
	"contact": "聯繫我們",
	"wechat_news": "關注我們",
	"wechat_contact": "微信聯繫",
	"wechat": "微信",
	"whats_app": "WhatsApp",
	"wechat_official_account": "微信公眾號",
	"line_official_account": "LINE官方帳號",
	"facebook": "臉書",
	"instagram": "Instagram",
	"linkedin": "領英",
	"redbook": "小紅書",
	"line": "LINE",
	"estimate": "聯繫我們-免費估價",
	"wakool_service_area": "Wakool服務區域",
	"our_locations": "辦事處地點",
	"send_us_message": "歡迎留言給我們",
	"leave_us_message": "歡迎留言給我們",
	"first_name": "名字",
	"last_name": "姓",
	"email_address": "電子郵件",
	"company": "公司",
	"phone_n": "電話號碼",
	"send_messae": "發送",
	"submit": "提交",
	"more_sales": "更高效的運輸流程如何幫助您完成更多銷售",
	"top_v": "俯視圖",
	"logistic": "頂尖的現代物流運輸服務",
	"freight": "Uber Freight 與 WattEV 進行裏程碑式的電動卡車試點",
	"uber": "優步貨運",
	"plans_deploy": "已宣布計劃在南加州的選定路線上部署電動卡車。",
	"invoice_uber": "電動汽車此舉將涉及優步貨運龐大的數字運營商網絡。該計劃是與",
	"heavy_ev": "，重型電動汽車設計和製造的先驅，優步貨運將使用其創新的電動車隊和充電基礎設施。",
	"first_shipper": "第一個參與試點的托運人將是",
	"global_leader": "，可持续物流领域的全球领导者。CHEP将开始在优步货运的南加州航线上运行电力。",
	"pillot_builds": "該試點建立在WattEV的使命之上，即開發一個全國性的重型充電設施網絡，為電動卡車車隊提供服務。",
	"chep_schedule": "CHEP將能夠在一個地方預訂、安排和完成裝載，跟蹤狀態和裝載KPI，並管理文書工作。",
	"blog_detail_addition": "除了零排放和無發動機噪音和煙霧的好處外，電動卡車的廣泛采用將減少對不可再生燃料的依賴——目前價格不穩定——還將降低維護成本。",
	"blog_detail_milestone": "該試點是電動貨運的一個裏程碑，也是優步貨運的首個電動汽車部署。",
	"blog_detail_electric": "電動卡車終於來了，我們很自豪能與WattEV合作，為優步貨運托運人提供更多更可持續的貨運方式，」優步貨運可持續發展負責人表示，",
	"blog_detail_frankiv": "伊利娜·弗蘭基夫",
	"blog_detail_profoundly": "電動卡車「將對物流產生深遠影響」-優步貨運",
	"blog_detail_added": "她補充道：「電動卡車將對物流產生深遠影響，我們很高興能夠建立技術平臺，使其能夠無縫集成到供應鏈中。」",
	"blog_detail_says": "Frankiv表示，優步貨運龐大的網絡「在將電動汽車整合到托運人運輸戰略中的物流復雜性方面處於獨特地位」。",
	"blog_detail_partnering": "她繼續說道：「通過與托運人、電動汽車運營商和WattEV等充電網絡提供商合作，我們是托運人和運營商將新興技術融入其供應鏈的首選網絡。」。",
	"marisa": "瑪麗莎·桑切斯·烏雷亞",
	"blog_detail_Director": ", 集保母公司布蘭堡全球供應鏈脫碳總監表示：「隨著行業嘗試可持續替代方案來補充傳統產能，該試點項目將為利益相關者提供有關電氣化貨運物流復雜性的寶貴見解。",
	"blog_detail_Director2": "「美國的第一個電動卡車試點是朝著正確方向邁出的一步，因為我們正朝著凈正碳影響的方向邁進。我們很自豪能與我們的運營商和客戶合作，共同擴大電氣化機會，實現我們共同的脫碳目標。」",
	"salim": "薩利姆·優素福紮德",
	"blog_detail_Director3": ",WattEV首席執行官表示：「我們很自豪能成為優步貨運及其客戶的首選零排放運輸解決方案。",
	"blog_detail_Director4": "「結合我們的運輸業務和卡車即服務模式，我們能夠使用優步貨運的數字平臺，為托運人及其客戶提供零排放卡車路線和服務。」",
	"top_v_ar": "熱門瀏覽文章",
	"blog_tx1": "洛杉磯頂級現代物流服務",
	"message": "消息",
	"more_articles": "更多文章",
	"views_8": "888次瀏覽",
	"contact_quote1": "輸入您的信息以查看報價",
	"contact_quote2": "請在下面填寫您的資訊以預訂負載，我們的團隊將盡快與您聯繫。",
	"contact_quote3": "請選擇",
	"contact_quote4": "月度交易量",
	"contact_quote5": "點擊「提交」，我同意我的信息將按照Wakool Transport的",
	"get_quotes": "提交",
	"cancel": "取消",
	"feedback_tx1": "我們非常重視您的反饋",
	"feedback_tx2": "為了持續提升用户體驗，請讓我們知道我們可以優化的地方！",
	"feedback_tx3": "有待改善的內容和建議",
	"feedback_tx4": "請輸入告訴我們如何改進",
	"feedback_tx5": "發送",
	"feedback_tx6": "非常感謝您的反饋",
	"feedback_tx7": "我們正在努力為用戶提供更好的體驗。你在這個過程中幫助了我們！",
	"feedback_tx8": "請輸入您的電子郵件",
	"back_home": "返回主頁",
	"footer_tx1": "威酷運輸",
	"footer_tx2": "Truckxi技術支持",
	"arizona": "亞利桑那州",
	"georgia": "格魯吉亞",
	"new_jersey": "新澤西州",
	"nevada": "內華達州",
	"california": "加利福尼亞州",
	"illinois": "伊利諾伊州",
	"new_york": "紐約",
	"texas": "德克薩斯州",
	"washington": "華盛頓",
	"virginia": "弗吉尼亞州",
	"maryland": "馬裏蘭州",
	"footer_all_tx1": "您最佳的倉儲、運輸，物流夥伴",
	"footer_all_title": "運輸監管識別號",
	"footer_all_mc": "MC 編號",
	"footer_all_dot": "DOT 編號",
	"footer_all_ca": "CA 編號",
	"footer_all_head": "總部",
	"footer_wakool_head": "Wakool總部",
	"footer_all_phone": "電話",
	"footer_all_email": "郵箱",
	"footer_all_address": "地址：加利福尼亞州羅蘭高地聖何塞大道19130號，CA 91748",
	"footer_all_address_top": "地址：加利福尼亞州羅蘭高地聖何塞大道19130號，",
	"footer_all_address_bottom": "CA 91748",
	"footer_all_taiwan": "臺灣辦事處",
	"footer_all_taiwan_branch": "臺灣分公司",
	"footer_all_china_branch": "中國分公司",
	"footer_all_china_branch_desc_one": "中國上海市虹口區吳淞路575號吉匯塔樓2523室",
	"footer_all_sec": "一段236號27樓",
	"footer_all_rd": "市政北二路，",
	"footer_all_xitun": "臺灣臺中西屯區",
	"footer_all_service": "服務區域",
	"footer_all_working": "工作時間",
	"footer_all_fri": "週一至週五：24小時全天候",
	"footer_all_sat": "週六：中午12時至下午4時（太平洋時間）",
	"footer_all_sunday": "周日：下午4時（太平洋時間）至次日中午12時",
	"footer_all_social": "社交媒體",
	"instant_port": "港口/提送",
	"instant_ware": "全美倉儲",
	"instant_service": "服務",
	"instant_ltl": "散貨運輸",
	"instant_ftl": "全美長途運輸",
	"instant_fba": "亞馬遜FBA",
	"instant_walmart_label": "亞馬遜/沃爾瑪",
	"instant_walmart": "沃爾瑪",
	"instant_quote": "快速詢價",
	"instant_fba_quote": "快速詢價",
	"instant_fba_please": "請點擊",
	"instant_fba_contact": "“聯系我們”",
	"instant_fba_information": "了解更多服務信息。",
	"instant_fba_thank": "謝謝！",
	"instant_fba_desired": "選擇您需要的服務",
	"amazon_FBA": "亞馬遜 FBA 服務",
	"walmart_delivery": "沃爾瑪運送",
	"select_service": "選擇服務",
	"instant_fba_ocean": "海運櫃直送",
	"new_york_or_new_jersey": "新澤西 / 紐約",
	"instant_fba_trans": "轉運全包（碼頭+倉庫+派送）",
	"instant_fba_loose": "散装托盤",
	"instant_fba_back": "返回",
	"instant_fba_last_page": "返回上一頁",
	"instant_warehouse_quote": "快速詢價",
	"instant_warehouse_please": "請點擊",
	"instant_warehouse_contact": "“聯系我們”",
	"instant_warehouse_information": "了解更多服務信息。",
	"instant_warehouse_thank": "謝謝！",
	"warehouse_location": "倉儲地址",
	"select_location": "選擇地址",
	"fba_select_location": "選擇地點",
	"instant_warehouse_los_angeles": "洛杉磯",
	"instant_warehouse_new_jersey": "新澤西",
	"instant_warehouse_oak_land": "奧克蘭",
	"instant_warehouse_seattle": "西雅圖",
	"instant_warehouse_houston": "休斯頓",
	"instant_warehouse_baltimore": "巴爾的摩",
	"instant_warehouse_savannah": "薩凡納",
	"instant_warehouse_chicago": "芝加哥",
	"instant_warehouse_back": "返回",
	"instant_otr_quote": "快速詢價",
	"instant_otr_rates": "在您正式下單鎖定價格之前，價錢可能會因市場變動而隨時有所調整。",
	"instant_otr_from": "從",
	"instant_otr_to": "到",
	"instant_otr_pick": "提貨日期",
	"instant_otr_drop": "到貨日期",
	"instant_otr_back": "我要預定",
	"instant_dra_port": "港口",
	"instant_dra_rail": "鐵路",
	"instant_dra_los": "洛杉磯/長灘",
	"instant_dra_sava": "薩凡納",
	"instant_dra_nj": "新澤西/紐約",
	"instant_dra_hou": "休斯頓",
	"instant_dra_sea": "西雅圖",
	"instant_dra_tacoma": "塔科馬",
	"instant_dra_norfolk": "弗吉尼亞州諾福克",
	"instant_dra_baltim": "巴爾的摩",
	"instant_dra_char": "查爾斯頓",
	"instant_dra_tampa": "坦帕",
	"instant_dra_jack": "傑克遜維爾",
	"instant_dra_miami": "邁阿密",
	"instant_dra_mobile": "莫比爾",
	"instant_dra_boston": "波士頓",
	"instant_dra_oakland": "奧克蘭",
	"instant_dra_vancouver": "加拿大溫哥華",
	"instant_dra_toronto": "加拿大多倫多",
	"instant_dra_chicago": "芝加哥",
	"instant_dra_dallas": "達拉斯",
	"instant_dra_atlanta": "亞特蘭大",
	"instant_dra_kansas": "堪薩斯",
	"instant_dra_zip": "郵政編碼或城市名稱",
	"instant_dra_infomation": "請填寫您的信息，獲得標準報價，無需註冊。",
	"instant_dra_trans": "運輸類型",
	"instant_dra_pick": "提貨點",
	"instant_dra_drop": "卸貨點",
	"instant_dra_input": "請輸入郵政編碼或城市名稱",
	"instant_war_thank": "感謝您的關註",
	"instant_war_soon": "我們正在開發這項服務，很快就會推出",
	"quotes_see": "輸入您的資訊以獲取報價並預訂",
	"quotes_privacy": "隱私聲明。",
	"quotes_volume": "請選擇每月數量",
	"quotes_address": "請輸入電子郵件地址",
	"quote_sc_pick": "提貨點",
	"quote_sc_delivery": "送貨點",
	"quote_sc_truck": "卡車",
	"quote_sc_included": "包含燃油費",
	"special_rates": "威酷運輸可為每月運輸五個或更多集裝箱的客戶，提供特別優惠。",
	"quote_sc_valid": "有效期自",
	"quote_sc_ctf": "CTF",
	"quote_sc_pier": "碼頭進出費",
	"quote_sc_terminal": "碼頭／鐵路擁堵費",
	"quote_sc_nights": "預提費",
	"quote_sc_drop": "放櫃費",
	"detention": "等候卸貨費",
	"quote_sc_special": "特別說明",
	"quote_sc_round": "預計往返行程",
	"quote_sc_miles": "英里",
	"quote_sc_thanks": "感謝您提交",
	"quote_sc_member": "感謝您的關註，我們將盡快與您聯系！",
	"home_los": "洛杉磯/長灘港",
	"home_sava": "薩凡納港",
	"home_hous": "休斯頓港",
	"home_jersey": "新澤西港",
	"home_angeles": "洛杉磯鐵路站",
	"home_rail": "達拉斯鐵路站",
	"instant_otr_vehicle_type": "車輛類型",
	"instant_otr_vehicle_type_dry": "乾櫃",
	"instant_otr_vehicle_type_refrigerated": "凍櫃",
	"instant_otr_vehicle_type_flatbed": "平板",
	"commodity": "貨物",
	"weight": "重量",
	"monthly_volume": "每月貨量",
	"reminder_note": "註意事項",
	"accessorial_charges": "附加費用",
	"additional_charges": "額外費用（若適用）",
	"storage_fee_policy": "場地費用政策",
	"empty_return_policy": "空櫃返還政策",
	"additional_charge_desc": "可能產生的額外費用僅在費用產生時收取。"
}

export default data;
