import { GET_QUOTES_MONTHLY_VOLUME_LIST } from "@/constants";
import { Button, Form, Input, Modal, Select } from 'antd';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import './index.scss';
import './mobile.scss';

const { Option } = Select;

const defaultProps = {
  isShow: false,
  onModalCancel: () => {
  },
  onGetQuotesSuccess: () => {
  },
};

const GetQuotes = forwardRef(function (props, ref) {
  const options = Object.assign({}, defaultProps, props);
  const { t } = useTranslation()
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'get-quotes-mobile-modal' : 'get-quotes-modal'

  const formRef = useRef(null);

  const handleModalCancel = () => {
    options.onModalCancel()
    formRef.current?.resetFields()
  }
  const monthlyVolumeList = GET_QUOTES_MONTHLY_VOLUME_LIST

  const handleGetQuotesBtnClick = () => {
    formRef.current?.submit()
  }
  const onFormFinish = (values) => {
    options.onGetQuotesSuccess(values)
  }
  const resetFields = () => formRef.current?.resetFields()

  useImperativeHandle(ref, () => ({
    resetFields,
  }))

  return (
    <Modal
      open={options.isShow}
      footer={null}
      maskClosable={false}
      onCancel={handleModalCancel}
      className={rootClassName}>
      <div className={'get-quotes-container'}>
        <div className={'get-quotes-title'}>{t('quotes_see')}</div>
        <div className={'get-quotes-des'}>
          {t('contact_quote2')}
        </div>
        <Form
          className={'get-quotes-form-container'}
          ref={formRef}
          layout={'vertical'}
          requiredMark={false}
          onFinish={onFormFinish}
          autoComplete="off">
          <div className={'get-quotes-form-row'}>
            <Form.Item
              label={t('first_name')}
              name="firstName">
              <Input />
            </Form.Item>
            <Form.Item
              label={t('last_name')}
              name="lastName">
              <Input />
            </Form.Item>
          </div>
          <Form.Item
            label={t('email_address')}
            name="emailAddress"
            rules={[
              {
                required: true,
                message: t('quotes_address'),
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            label={t('company')}
            name="company">
            <Input />
          </Form.Item>
          <Form.Item
            label={t('phone_n')}
            name="phoneNumber">
            <Input />
          </Form.Item>
          <Form.Item
            label={t("monthly_volume")}
            name="monthlyVolume"
            id={'get-quotes-monthly-volume-root'}
            rules={[
              {
                required: true,
                message: t('quotes_volume'),
              },
            ]}>
            <Select
              placeholder={t('contact_quote3')}
              getPopupContainer={() => document.getElementById('get-quotes-monthly-volume-root')}>
              {
                monthlyVolumeList.map((item, index) => {
                  return <Option
                    value={item.value}
                    key={'monthly_volume_' + index}>
                    {item.label}
                  </Option>
                })
              }
            </Select>
          </Form.Item>
          <Form.Item
              label={t('commodity')}
              name="commodity"
          >
            <Input />
          </Form.Item>
          <Form.Item
              label={t('weight')}
              name="weight"
          >
            <Input />
          </Form.Item>
        </Form>
        <div className={'get-quotes-tip'}>
          {t('contact_quote5')}
          &nbsp;
          <a
            href="/"
            target={'_blank'}
            className={'get-quotes-link'}>
            {t('quotes_privacy')}
          </a>
          .
        </div>
        <Button
          className={'get-quotes-btn-1'}
          onClick={handleGetQuotesBtnClick}>
          {t('get_quotes')}
        </Button>
        <Button
          className={'get-quotes-btn-2'}
          type="link"
          onClick={handleModalCancel}>
          {t('cancel')}
        </Button>
      </div>
    </Modal>
  )
})

export default GetQuotes
