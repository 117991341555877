import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux';
import { Button, Modal, Collapse } from 'antd'
import { useNavigate } from 'react-router-dom';
import { uniq } from 'lodash'

import './index.scss'
import './mobile.scss'
import { GET_QUOTES_SUCCESS_TYPE_1_KEY, GET_QUOTES_SUCCESS_TYPE_2_KEY } from '@/constants'
import { getAddDaysDate } from '@/common';
import { useTranslation } from 'react-i18next';

const { Panel } = Collapse;

const defaultProps = {
  isShow: false,
  onModalCancel: () => {
  },
  type: GET_QUOTES_SUCCESS_TYPE_1_KEY,
};

export default function GetQuotesSuccess(props) {
  const options = Object.assign({}, defaultProps, props);

  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'get-quotes-success-mobile-modal' : 'get-quotes-success-modal'
  const anInstantQuoteState = useSelector(state => state.anInstantQuote)
  const anInstantQuoteData = anInstantQuoteState.data

  const handleBackToHome = () => {
    options.onModalCancel()
    navigate('/')
  }
  const renderBackToHomeBtn = () => <Button className={'get-quotes-success-btn'} onClick={handleBackToHome}>{t('back_home')}</Button>

  const FIXED_EXPENSES_KEY = '0'
  const STORAGE_FEE_KEY = '1'
  const ACCESSORIAL_CHARGES_KEY = '2'
  const ADDITIONAL_CHARGES_KEY = '3'
  const EMPTY_RETURN_POLICY_KEY = '4'
  const [activeKey, setActiveKey] = useState('')
  const activeKeys = useMemo(() => [FIXED_EXPENSES_KEY, activeKey].filter(key => key), [activeKey])

  const renderHeader = (title, des, key) => <div className={'get-quotes-success-1-right-header-container'}>
    <div className={'get-quotes-success-1-right-header-title'}>{title}</div>
    {des && activeKeys.includes(key) && <div className={'get-quotes-success-1-right-header-des'}>{des}</div>}
  </div>
 const onChange = newActiveKeys => {
  const newActiveKeyList = uniq(newActiveKeys).filter(key => key !== FIXED_EXPENSES_KEY)
  const newActiveKey = newActiveKeyList[newActiveKeyList.length - 1]
  setActiveKey(newActiveKey);
}

const renderListItem = (item, index, dataIndex) => <div
  className={'get-quotes-success-1-right-list-item-container'}
  key={dataIndex + '_' + index}>
  <div className={'get-quotes-success-1-right-list-item-left-container'}>
    <div
      style={item.style}
      className={'get-quotes-success-1-right-list-item-title'}>
      {item.title}
    </div>
    {item.des && <div className={'get-quotes-success-1-right-list-item-des'}>{item.des}</div>}
  </div>
  {item.subTitle && <div className={'get-quotes-success-1-right-list-item-sub-title'}>{item.subTitle}</div>}
</div>
const renderDataItem = (item, index) => <Panel header={item.header} key={item.key}>
  {item.list.map((listItem, listIndex) => renderListItem(listItem, listIndex, index))}
</Panel>

const renderExpandIcon = ({ panelKey }) => {
  return panelKey !== FIXED_EXPENSES_KEY ? <div className={'get-quotes-success-1-right-expand-icon-container'}>
    <img src={require('@/assets/images/GetAnInstantCode/expand-icon.png')} alt={''} />
  </div> : null
}
const getListData = (list, key) => {
  if (!list) return []
  return list.map((item, index) => {
    return {
      ...item,
      title: item.Title,
      style: (key === STORAGE_FEE_KEY && index === 0) ? {
        color: '#EE1D52'
      } : {},
      subTitle: item.Price,
      des: item.Description,
    }
  })
}
  const rightContentData = useMemo(() => {
    const {
      accessorialCharges,
      additionalCharges,
      emptyReturnPolicy,
      fixedExpense,
      storageFee,
    } = anInstantQuoteData
    return [
      {
        header: renderHeader(t('reminder_note'), '', FIXED_EXPENSES_KEY),
        key: FIXED_EXPENSES_KEY,
        list: getListData(fixedExpense, FIXED_EXPENSES_KEY),
      },
      // {
      //   header: renderHeader(t('accessorial_charges'), 'Will apply to each containers', ACCESSORIAL_CHARGES_KEY),
      //   key: ACCESSORIAL_CHARGES_KEY,
      //   list: getListData(accessorialCharges, ACCESSORIAL_CHARGES_KEY),
      // },S
      {
        header: renderHeader(t('additional_charges'), t('additional_charge_desc'), ADDITIONAL_CHARGES_KEY),
        key: ADDITIONAL_CHARGES_KEY,
        list: getListData(additionalCharges, ADDITIONAL_CHARGES_KEY),
      },
      {
        header: renderHeader(t('storage_fee_policy'), '', STORAGE_FEE_KEY),
        key: STORAGE_FEE_KEY,
        list: getListData(storageFee, STORAGE_FEE_KEY),
      },
      {
        header: renderHeader(t('empty_return_policy'), '', EMPTY_RETURN_POLICY_KEY),
        key: EMPTY_RETURN_POLICY_KEY,
        list: getListData(emptyReturnPolicy, EMPTY_RETURN_POLICY_KEY),
      }
    ]
  }, [anInstantQuoteData])
  const renderRightContent = () => {

    return <Collapse
      className={'get-quotes-success-1-right-collapse'}
      onChange={onChange}
      expandIconPosition={'end'}
      accordion={false}
      bordered={false}
      ghost={true}
      expandIcon={renderExpandIcon}
      activeKey={activeKeys}>
      {rightContentData.map((dataItem, dataIndex) => renderDataItem(dataItem, dataIndex))}
    </Collapse>
  };
  const renderContent1 = () => {
    const renderRightIcon = () => <img src={require('@/assets/images/GetAnInstantCode/right-icon.png')} alt="" />
    const isEn = i18n.language === 'en'
    const chargesData = {
      header: renderHeader(t('accessorial_charges'), 'Will apply to each containers', ACCESSORIAL_CHARGES_KEY),
      key: ACCESSORIAL_CHARGES_KEY,
      list: getListData(anInstantQuoteData?.accessorialCharges, ACCESSORIAL_CHARGES_KEY),
    };
    return <div className={'get-quotes-success-1-container'}>
      <div className={'get-quotes-success-1-left-container'}>
        <div className={'get-quotes-success-1-left-title'}>{t('instant_fba_quote')}</div>
        <div className={'get-quotes-success-1-left-time-line-container'}>
          <div className={'get-quotes-success-1-left-time-line-left-container'}>
            <div className={'get-quotes-success-1-left-time-line-start-icon'} />
            <div className={'get-quotes-success-1-left-time-line-left-line'} />
            <div className={'get-quotes-success-1-left-time-line-stop-icon'} />
          </div>
          <div className={'get-quotes-success-1-left-time-line-right-container'}>
            <div className={'get-quotes-success-1-left-time-line-start-content'}>
              <div className={'get-quotes-success-1-left-time-line-start-top-container'}>
                <div>{t('quote_sc_pick')}</div>
                {renderRightIcon()}
              </div>
              <div className={'get-quotes-success-1-left-time-line-start-text'}>
                {anInstantQuoteData.pickup_address}
              </div>
            </div>
            <div className={'get-quotes-success-1-left-time-line-stop-content'}>
              <div className={'get-quotes-success-1-left-time-line-stop-top-container'}>
                <div>{t('quote_sc_delivery')}</div>
                {renderRightIcon()}
              </div>
              <div className={'get-quotes-success-1-left-time-line-stop-text'}>
                {anInstantQuoteData.city}, {anInstantQuoteData.state} {anInstantQuoteData.zip}
              </div>
            </div>
          </div>
        </div>
        <div className={'get-quotes-success-1-left-info-container'}>
          <div className={'get-quotes-success-1-left-info-title'}>
            <div className={isEn ? '' : 'vertical'}>{t('quote_sc_truck')}</div>
          </div>
          <div className={'get-quotes-success-1-left-info-right-container'}>
            <div className={'get-quotes-success-1-left-info-right-top-container'}>
              <div className={'get-quotes-success-1-left-info-money'}><div>{anInstantQuoteData.trucking_rates}</div>{options.type !== GET_QUOTES_SUCCESS_TYPE_1_KEY && <div className={'special-rates'}>{t('special_rates')}</div>}</div>
              <div className={'get-quotes-success-1-left-info-tip'}>{t('quote_sc_included')}</div>
              <div className={'get-quotes-success-1-left-info-valid'}>
                {t('quote_sc_valid')} {getAddDaysDate()} to {getAddDaysDate(30)}
              </div>
            </div>
            <div className={'get-quotes-success-1-left-info-bottom-container'}>
              <div className={'get-quotes-success-1-left-info-bottom-text'}>{t('quote_sc_round')} </div>
              <div className={'get-quotes-success-1-left-info-bottom-miles'}>
                {anInstantQuoteData.miles} {t('quote_sc_miles')}
              </div>
            </div>
            <div className={'get-quotes-success-1-left-info-content-container'}>
              <div className={'get-quotes-success-1-left-info-content-item-container'}>
                <div className={'get-quotes-success-1-left-info-content-item-key'}>{t('quote_sc_drop')}: </div>
                <div className='special-instruction-text-wrap'>{anInstantQuoteData.drop_fee}</div>
              </div>
              <div className={'get-quotes-success-1-left-info-content-item-container'}>
                <div className={'get-quotes-success-1-left-info-content-item-key'}>{t('detention')}: </div>
                <div className='special-instruction-text-wrap'>{anInstantQuoteData.detention}</div>
              </div>
              <div className={'get-quotes-success-1-left-info-content-item-container'}>
                <div className={'get-quotes-success-1-left-info-content-item-key'}>{t('quote_sc_special')}: </div>
                <div className='special-instruction-text-wrap'>{anInstantQuoteData.special_instruction}</div>
              </div>
            </div>
          </div>
        </div>
        <div className={'get-quotes-success-1-right-container'} style={{width: '100%'}}>
          <Collapse
            className={'get-quotes-success-1-right-collapse'}
            accordion={false}
            bordered={false}
            ghost={true}
            defaultActiveKey={['2']}
          >
            {renderDataItem(chargesData)}
          </Collapse>
        </div>
      </div>
      <div className={'get-quotes-success-1-right-container'}>
        {renderRightContent()}
        {renderBackToHomeBtn()}
      </div>
    </div>
  }

  const renderContent2 = () => {
    return <div className={'get-quotes-success-2-container'}>
      <div className={'get-quotes-success-2-title'}>
        {t('quote_sc_thanks')}
      </div>
      <div className={'get-quotes-success-2-tip'}>
        {t('quote_sc_member')}
      </div>
      {renderBackToHomeBtn()}
    </div>
  }

  return (
    <Modal
      open={options.isShow}
      footer={null}
      maskClosable={false}
      onCancel={options.onModalCancel}
      className={rootClassName}>
      {options.type === GET_QUOTES_SUCCESS_TYPE_1_KEY && renderContent1()}
      {options.type === GET_QUOTES_SUCCESS_TYPE_2_KEY && renderContent1()}
    </Modal>
  )
}
