/**
 * 包含n个action creator
 * 异步action
 * 同步action
 */

import {
  AUTH_SUCCESS,
  GET_AN_INSTANT_QUOTE_SUCCESS,
  LANGUAGE_CHANGE,
} from './action-types';

// 授权成功的action
const authSuccess = user => ({ type: AUTH_SUCCESS, data: user });

// 登录异步action
export const login = user => {
  return async dispatch => {
    dispatch(authSuccess({}))
  }
};

export const getAnInstantQuoteSuccessAction = data => ({ type: GET_AN_INSTANT_QUOTE_SUCCESS, data: data });

// 语言改变
export const languageChangeAction = (data) => ({ type: LANGUAGE_CHANGE, data });
