import React from 'react'
import { Button, Modal } from 'antd'
import './index.scss'
import './mobile.scss'
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const defaultProps = {
    isShow: false,
    onModalCancel: () => { },
};

export default function ComingSoon(props) {
    const navigate = useNavigate()
    const options = Object.assign({}, defaultProps, props);
    const { t } = useTranslation()
    const commonState = useSelector(state => state.common)
    const rootClassName = commonState.isMobile ? 'getAnInstantCodeComingSoon-mobile' : 'getAnInstantCodeComingSoon'
    const goHome = () => {
        //关闭弹窗并跳转至home页面
        options.onModalCancel();
        navigate('/home')
    }
    return (
        <div>
            <Modal
                open={options.isShow}
                footer={null}
                maskClosable={false}
                onCancel={options.onModalCancel}
                className={rootClassName}
            >
                <h1>{t('instant_war_thank')}</h1>
                <p>{t('instant_war_soon')}</p>
                {/* <img src={require('@/assets/images/GetAnInstantCode/comingSoon.png')} alt="" /> */}
                <Button onClick={() => goHome()}>{t('back_home')}</Button>
            </Modal>
        </div>
    )
}
