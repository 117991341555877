import React, {useState} from 'react'
import {Button, Modal, Form, Input, Select, message, Spin} from 'antd'
import './index.scss'
import './mobile.scss'
import { useSelector } from "react-redux";
import { GET_QUOTES_MONTHLY_VOLUME_LIST } from "@/constants";
import { useTranslation } from 'react-i18next';
import {getUberFreight} from "@/api";

const { Option } = Select;

const defaultProps = {
  isShow: false,
  data: null,
  onModalCancel: () => { },
};
/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid Email Address!'
  },
};
/* eslint-enable no-template-curly-in-string */

export default function GetAnInstantCodeWarehouse(props) {
  const monthlyVolumeList = GET_QUOTES_MONTHLY_VOLUME_LIST
  const options = Object.assign({}, defaultProps, props);
  const { t } = useTranslation()
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'contactToBook-mobile' : 'contactToBook'
  const [loading, setLoading] = useState(false);
  const cancel = () => {
    options.onModalCancel();
  }
  const onFinish = (values) => {
    setLoading(true);
    const {pickUpLocationData, dropOffLocationData, pickUpDate, dropOffDate, vehicleType} = options.data
    const {firstName, lastName, emailAddress, company, phoneNumber, monthlyVolume, commodity, weight} = values
    const [drop_off_email_time, rate_price] = dropOffDate.split(' ');
    //call api
    const params = {
      pickup: {
        address: pickUpLocationData.address,
        city: pickUpLocationData.city,
        state: pickUpLocationData.state,
        zipcode: pickUpLocationData.postcode,
        country: pickUpLocationData.country,
      },
      dropoff: {
        address: dropOffLocationData.address,
        city: dropOffLocationData.city,
        state: dropOffLocationData.state,
        zipcode: dropOffLocationData.postcode,
        country: dropOffLocationData.country,
      },
      date: pickUpDate,
      drop_off_email_time,
      rate_price,
      vehicle_type: vehicleType,
      First_Name: firstName,
      Last_Name: lastName,
      Email_Address: emailAddress,
      Company: company,
      Phone_Number: phoneNumber,
      Monthly_Volume: monthlyVolume,
      Commodity: commodity,
      Weight: weight
    }
    getUberFreight(params)
      .then(res => {
        message.success(t('quote_sc_thanks'))
        cancel()
      }).finally(() => setLoading(false))
  };
  return (
    <div>
      <Modal
        open={options.isShow}
        footer={null}
        maskClosable={false}
        onCancel={options.onModalCancel}
        className={rootClassName}
      >
        <Spin spinning={loading} size="large">
          <h1>{t('contact_quote1')}</h1>
          <p>{t('contact_quote2')}</p>
          <Form
            layout="vertical"
            name="nest-messages"
            onFinish={onFinish}
            className="contactToBook-form-main"
            style={{
              maxWidth: 620,
              marginLeft: 0,
              marginRight: 0
            }}
            validateMessages={validateMessages}
            autoComplete="off"
            requiredMark={false}
          >
            <div className='display-center'>
              <Form.Item
                name={'firstName'}
                label={t('first_name')}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={'lastName'}
                label={t('last_name')}
              >
                <Input />
              </Form.Item>
            </div>
            <Form.Item
              name={'emailAddress'}
              label={t('email_address')}
              rules={[
                {
                  required: true,
                  type: 'email',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={'company'}
              label={t('company')}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={'phoneNumber'}
              label={t('phone_n')}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t('contact_quote4')}
              name="monthlyVolume"
              id={'contact-to-book-monthly-volume-root'}
              rules={[
                {
                  required: true,
                },
              ]}>
              <Select
                placeholder={t('contact_quote3')}
                getPopupContainer={() => document.getElementById('contact-to-book-monthly-volume-root')}>
                {
                  monthlyVolumeList.map((item, index) => {
                    return <Option
                      value={item.value}
                      key={'monthly_volume_' + index}>
                      {item.label}
                    </Option>
                  })
                }
              </Select>
            </Form.Item>
            <Form.Item
                label={t('commodity')}
                name="commodity"
                rules={[
                  {
                    required: true,
                    message: t('quotes_commodity'),
                  },
                ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
                label={t('weight')}
                name="weight"
                rules={[
                  {
                    required: true,
                    message: t('quotes_weight'),
                  },
                ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <p>{t('contact_quote5')}
                <a target="_blank" href="https://www.uber.com/legal/zh/document/?name=uber-freight-privacy-notice&country=united-states&lang=en" rel="noreferrer">Privacy Notice.</a>
              </p>
              <Button htmlType="submit" className='contactToBook-btn'>{t('get_quotes')}</Button>
              <Button className='contactToBook-btn' type="text" onClick={() => cancel()}>{t('cancel')}</Button>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </div>
  )
}
