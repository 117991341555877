import React, { useState } from 'react'
import './index.scss'
import './mobile.scss'
import { useSelector } from "react-redux";
import img1 from '@/assets/images/GetAnInstantCode/OTRNew.png'
import img2 from '@/assets/images/GetAnInstantCode/portRailDrayageNew.png'
import img3 from '@/assets/images/GetAnInstantCode/LTLNew.png'
import img4 from '@/assets/images/GetAnInstantCode/warehouseNew.png'
import img5 from '@/assets/images/GetAnInstantCode/amazonNew.png'
import img6 from '@/assets/images/GetAnInstantCode/walmartNew.png'
import {
  GET_AN_INSTANT_CODE_OTR_KEY,
  GET_AN_INSTANT_CODE_PORT_RAIL_DRAYAGE_KEY,
  GET_AN_INSTANT_CODE_LTL_KEY,
  GET_AN_INSTANT_CODE_WAREHOUSE_KEY,
  GET_AN_INSTANT_CODE_FBA_KEY,
  GET_AN_INSTANT_CODE_WALMART_KEY
} from '@/constants'
import GetAnInstantCodePortRailDrayage from '@/components/GetAnInstantCodePortRailDrayage'
import GetAnInstantCodeWarehouse from '@/components/GetAnInstantCodeWarehouse'
import GetAnInstantCodeOTR from '@/components/GetAnInstantCodeOTR'
import ContactToBook from '@/components/ContactToBook'
import GetAnInstantCodeFBA from '@/components/GetAnInstantCodeFBA'
import GetAnInstantCodeWalmart from '@/components/GetAnInstantCodeWalmart'
import { useTranslation } from 'react-i18next';
import ComingSoon from "@/components/ComingSoon";

const defaultProps = {
  onItemClick: () => {
  },
  showGetAnInstantCodeModal: () => {
  },
};

const Footer = (props) => {
  const options = Object.assign({}, defaultProps, props);

  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'getAnInstantCode-mobile-main' : 'getAnInstantCode-main'
  const { t } = useTranslation();
  const [isShowModalPortRailDrayage, setIsShowModalPortRailDrayage] = useState(false);
  const [isShowModalWarehouse, setIsShowModalWarehouse] = useState(false);
  const [isShowModalOTR, setIsShowModalOTR] = useState(false);
  const [isShowModalContactToBook, setIsShowModalContactToBook] = useState(false);
  const [isShowModalFBA, setIsShowModalFBA] = useState(false);
  const [isShowModalWalmart, setIsShowModalWalmart] = useState(false);
  const [isShowComingSoon, setIsShowComingSoon] = useState(false);
  const [contactToData, setContactToData] = useState(null);
  const getAnInstantQuoteModeList = [
    {
      imgUrl: img2,
      title1: t('instant_port'),
      key: GET_AN_INSTANT_CODE_PORT_RAIL_DRAYAGE_KEY,
      backgroundColor: '#F12559',
    },
    {
      imgUrl: img4,
      title1: t('instant_ware'),
      title2: t('instant_service'),
      key: GET_AN_INSTANT_CODE_WAREHOUSE_KEY,
      backgroundColor: '#0A12D8',
    },
    // {
    //   imgUrl: img3,
    //   title1: t('instant_ltl'),
    //   title2: t('instant_service'),
    //   key: GET_AN_INSTANT_CODE_LTL_KEY,
    //   backgroundColor: '#5FBB17',
    // },
    {
      imgUrl: img1,
      title1: t('instant_ftl'),
      title2: t('instant_service'),
      key: GET_AN_INSTANT_CODE_OTR_KEY,
      backgroundColor: '#00AB61',
    },
    {
      imgUrl: img5,
      title1: t('instant_fba'),
      title2: t('instant_service'),
      key: GET_AN_INSTANT_CODE_FBA_KEY,
      backgroundColor: '#FFC266',
    },
    // {
    //   imgUrl: img6,
    //   title1: t('instant_walmart'),
    //   title2: t('instant_service'),
    //   key: GET_AN_INSTANT_CODE_WALMART_KEY,
    //   backgroundColor: '#196DBD',
    // },
  ]

  const showModalPortRailDrayageModal = () => {
    setIsShowModalPortRailDrayage(true);
  };
  const hideModalPortRailDrayageModal = () => {
    setIsShowModalPortRailDrayage(false);
  };
  //Warehouse
  const showModalWarehouse = () => {
    setIsShowModalWarehouse(true);
  };
  const hideModalWarehouse = () => {
    setIsShowModalWarehouse(false);
  };
  //OTR
  const showModalOTR = () => {
    setIsShowModalOTR(true);
  };
  const hideModalOTR = () => {
    setIsShowModalOTR(false);
  };
  //Contact to Book
  const showModalContactToBook = () => {
    setIsShowModalContactToBook(true);
  };
  const hideModalContactToBook = () => {
    setIsShowModalContactToBook(false);
    setContactToData(null);
  };
  const goContactToBook = (data) => {
    setContactToData(data)
    showModalContactToBook()
  };
  //FBA
  const showModalFBA = () => {
    setIsShowModalFBA(true);
  };
  const hideModalFBA = () => {
    setIsShowModalFBA(false);
  };
  //Walmart
  const showModalWalmart = () => {
    setIsShowModalWalmart(true);
  };
  const hideModalWalmart = () => {
    setIsShowModalWalmart(false);
  };
  const showComingSoon = () => {
    setIsShowComingSoon(true);
  }
  const hideComingSoon = () => {
    setIsShowComingSoon(false);
  }
  const handelItemClick = (item, index) => {
    switch (item.key) {
      case GET_AN_INSTANT_CODE_PORT_RAIL_DRAYAGE_KEY:
        showModalPortRailDrayageModal()
        break;
      case GET_AN_INSTANT_CODE_WAREHOUSE_KEY:
        showModalWarehouse()
        break;
      case GET_AN_INSTANT_CODE_OTR_KEY:
        showModalOTR()
        break;
      case GET_AN_INSTANT_CODE_LTL_KEY:
        showComingSoon()
        break;
      case GET_AN_INSTANT_CODE_FBA_KEY:
        showModalFBA()
        break;
      case GET_AN_INSTANT_CODE_WALMART_KEY:
        showModalWalmart()
        break;
      default:
    }
  }

  return (
    <div className={rootClassName}>
      <h1 className='getAnInstantCode-main-title'></h1>
      <div className='getAnInstantCode-main-content'>
        <div className='getAnInstantCode-main-content-ul'>
          {getAnInstantQuoteModeList.map((item, index) =>
            <div
              onClick={() => {
                options.onItemClick(item, index);
                handelItemClick(item, index)
              }}
              key={'get-an-instant-quote-mode-list' + index}
              className="getAnInstantCode-main-content-ul-li">
              <div>
                {item.title1}
                {item.title2 && <span style={{marginLeft: '10px'}}>{item.title2}</span>}
              </div>
              <img src={item.imgUrl} alt="" width='100%' />
            </div>)}
        </div>
      </div>
      <GetAnInstantCodePortRailDrayage
        isShow={isShowModalPortRailDrayage}
        onModalCancel={hideModalPortRailDrayageModal}
      />
      <GetAnInstantCodeWarehouse
        isShow={isShowModalWarehouse}
        onModalCancel={hideModalWarehouse}
        showGetAnInstantCodeModal={options.showGetAnInstantCodeModal}
      />
      <GetAnInstantCodeOTR
        isShow={isShowModalOTR}
        onModalCancel={hideModalOTR}
        onModalGoContactToBook={goContactToBook}
      />
      <ContactToBook
        isShow={isShowModalContactToBook}
        data={contactToData}
        onModalCancel={hideModalContactToBook}
      />
      <GetAnInstantCodeFBA
        isShow={isShowModalFBA}
        onModalCancel={hideModalFBA}
        showGetAnInstantCodeModal={options.showGetAnInstantCodeModal}
      />
      <GetAnInstantCodeWalmart
        isShow={isShowModalWalmart}
        onModalCancel={hideModalWalmart}
        showGetAnInstantCodeModal={options.showGetAnInstantCodeModal}
      />
      <ComingSoon 
        isShow={isShowComingSoon} 
        onModalCancel={hideComingSoon}
      />
    </div>
  )
}
export default Footer
