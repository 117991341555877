import React, {useState, useImperativeHandle, forwardRef} from 'react';
import {Map, GoogleApiWrapper} from 'google-maps-react-18-support';

import './index.scss'

const recordsObj = {}
const MapContainer = forwardRef(function MapContainer(props, ref) {
  const [autocompleteService, setAutocompleteService] = useState(null)
  const [google, setGoogle] = useState(null)
  let getSuggestionsListTimeoutId = null
  const getSuggestionsListByText = (input, callback) => {
    if (getSuggestionsListTimeoutId) {
      clearTimeout(getSuggestionsListTimeoutId)
      getSuggestionsListTimeoutId = null
    }
    getSuggestionsListTimeoutId = setTimeout(() => {
      let result = []
      if (!input) {// 输入的值为空，返回空数组
        console.log('输入的值为空，返回空数组');
        return callback(result)
      }
      if (recordsObj[input]) {// 有记录，使用记录返回值
        console.log('有记录，使用记录返回值');
        return callback(recordsObj[input])
      }
      autocompleteService.getQueryPredictions({
        input,
        componentRestrictions: {
          country: ['us']
        }
      }, (predictions, status) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK || !predictions) {
          // 参数不对，返回默认的空数组
          console.log('参数不对，返回默认的空数组');
          return callback(result)
        }
        result = predictions.map(item => {
          const {description, place_id} = item
          return {
            ...item,
            label: description,
            value: description
          }
        })
        // 将搜索结果记录下来
        recordsObj[input] = result
        console.log('将搜索结果记录下来');
        callback(result)
      })
    }, 10)
  }
  const onReady = (mapProps, map) => {
    const {google} = mapProps;
    const autocompleteService = new google.maps.places.AutocompleteService(map);
    setGoogle(google);
    setAutocompleteService(autocompleteService)
  }

  useImperativeHandle(ref, () => ({
    getSuggestionsListByText,
  }))
  
  return (
    <Map
      visible={false}
      google={props.google}
      zoom={11}
      initialCenter={{
        lat: 34.1013404,
        lng: -118.3014496
      }}
      onReady={onReady}
    />
  );
})

export default GoogleApiWrapper({
  apiKey: 'AIzaSyA8yIWNfPAvSLVw6cTd29UKcklmvKsdlDA',
})(MapContainer)
