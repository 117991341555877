import React from 'react'
import './index.scss'
import './mobile.scss'
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const commonState = useSelector(state => state.common)
  const { t } = useTranslation()
  const rootClassName = commonState.isMobile ? 'footer-mobile-main' : 'footer-main'

  return (
    <div className={rootClassName}>
      © 2024 {t('footer_tx1')}  | <span>{t('footer_tx2')}</span>
    </div>
  )
}
export default Footer
