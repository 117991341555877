import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import {useCallback, useEffect, useState} from 'react';
import './index.scss';
import './mobile.scss';

export default function Preview(props) {
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'mobile-preview-page-style' : 'preview-page-style'
  const { t } = useTranslation();
  const dataUrl = window.location.search.split('url=')[1]
  const type = dataUrl.substring(dataUrl.lastIndexOf('.')+1, dataUrl.length)
  const [viewerHeight, setViewHeight] = useState('100%');
  const docs = [
    { uri: dataUrl,fileType: type },
  ];
  useEffect(() => {
    //禁止右键
    document.oncontextmenu = function (event) {
      try {
          return false;
      } catch (e) {
          return false;
      }
    }
  }, [])
  const wrapperRef = useCallback((node) => {
    if (node) {
      const rect = node.getBoundingClientRect();
      setViewHeight(rect.height + 'px');
    }
  }, []);
  return (
    <div className={rootClassName} ref={wrapperRef}>
      <DocViewer
        documents={docs}
        style={{height: viewerHeight}}
        prefetchMethod='GET'
      />
    </div>
  )
}
