const data = {
	"language_en": "English",
	"language_zh_cn": "中文(简体)",
	"language_zh_tw": "中文(繁体)",
	"home": "Home",
	"quote": "Get Quote",
	"feedback": "Feedback",
	"contact": "Contact Us",
	"wechat_news": "WeChat news",
	"wechat_contact": "WeChat Contact",
	"wechat": "WeChat",
	"whats_app": "WhatsApp",
	"wechat_official_account": "Wechat Official Account",
	"line_official_account": "Line Official Account",
	"facebook": "Facebook",
	"instagram": "Instagram",
	"linkedin": "Linkedin",
	"redbook": "Xiaohongshu",
	"line": "LINE",
	"estimate": "GET IN TOUCH - GET FREE ESTIMATE",
	"wakool_service_area": "Wakool Service Area",
	"our_locations": "Our Locations",
	"send_us_message": "SEND US MESSAGE",
	"leave_us_message": "Leave Us A Message",
	"first_name": "First Name",
	"last_name": "Last Name",
	"email_address": "Email Address",
	"company": "Company",
	"phone_n": "Phone Number",
	"send_messae": "Send Message",
	"submit": "Submit",
	"more_sales": "How a more efficient transport process can help you close more sales",
	"top_v": "Top View",
	"logistic": "Top modern logistic transport service",
	"freight": "Uber Freight in milestone electric trucks pilot with WattEV",
	"uber": "Uber Freight",
	"plans_deploy": "has announced plans to deploy electric trucks on select routes in Southern California.",
	"invoice_uber": "The EV move will involve Uber Freight's expansive network of digitally enabled carriers. The programme is a collaborative effort with",
	"heavy_ev": ", a pioneer in the design and manufacture of heavy duty EVs, and whose innovative electric fleet and charging infrastructure Uber Freight will use.",
	"first_shipper": "The first shipper to participate in the pilot will be",
	"global_leader": ", a global leader in sustainable logistics. CHEP will begin running electric capacity on Uber Freight’s Southern California route.",
	"pillot_builds": "The pilot builds on WattEV’s mission to develop a nationwide network of heavy-duty charging facilities that will serve fleets of electric trucks.",
	"chep_schedule": "CHEP will be able to book, schedule, and complete loads, track status and load KPIs, and manage paperwork all in one place.",
	"blog_detail_addition": "In addition to the benefits of zero emissions and the absence of engine noise and fumes, widespread adoption of electric trucks will reduce reliance upon non-renewable fuels – the price of which is volatile right now – and also reduce maintenance costs.",
	"blog_detail_milestone": "The pilot is a milestone in electric freight transportation and is Uber Freight’s first EV deployment.",
	"blog_detail_electric": "Electric trucks are finally here, and we’re proud to partner with WattEV to offer Uber Freight shippers even more ways to move freight more sustainably,” said Uber Freight Head of Sustainability,",
	"blog_detail_frankiv": "Illina Frankiv",
	"blog_detail_profoundly": "E-trucks 'will impact logistics profoundly' - Uber Freight",
	"blog_detail_added": "She added:” “Electric trucks will have a profound impact on logistics, and we’re excited to build the technology platform to enable their seamless integration into supply chains”",
	"blog_detail_says": "Frankiv says Uber Freight’s expansive network is “uniquely positioned to navigate the logistical complexities of integrating electric vehicles into shippers’ transportation strategy”.",
	"blog_detail_partnering": "“By partnering every step of the way with shippers, EV carriers and charging-network providers like WattEV, we’re the proven network of choice for shippers and carriers to integrate emerging technologies into their supply chains,” she continued.",
	"marisa": "Marisa Sánchez Urrea",
	"blog_detail_Director": ", Director of Global Supply Chain Decarbonisation at Brambles – CHEP’s parent company – said: “As the industry experiments with sustainable alternatives to complement traditional capacity, this pilot will provide stakeholders with valuable insight into the logistical complexities of electrifying freight transportation.",
	"blog_detail_Director2": "“This first electric truck pilot in the US is a step in the right direction, as we move towards a net positive carbon impact. We are proud to partner with our carriers and our customers as together we scale-up electrification opportunities and deliver on our shared decarbonization goals.”",
	"salim": "Salim Youssefzadeh",
	"blog_detail_Director3": ", CEO of WattEV, said: “We are proud to be a zero emission transport solution of choice for Uber Freight and their customers.",
	"blog_detail_Director4": "“Combining our transport business and our Truck-as-a-Service model, we are able to use Uber Freight’s digital platform to serve the shippers and their customers with our zero-emission truck routes and services.”",
	"top_v_ar": "Top View articles",
	"blog_tx1": "Top modern logistic services in Los Angeles",
	"message": "Message",
	"more_articles": "More articles",
	"views_8": "888 views",
	"contact_quote1": "ENTER YOUR INFORMATION TO SEE QUOTES",
	"contact_quote2": "Please fill out your information below to book the load and our team will contact you shortly.",
	"contact_quote3": "Please choose",
	"contact_quote4": "Monthly Volume",
	"contact_quote5": "By clicking “Submit”, I agree that my information will be used in accordance with Wakool Transport’s",
	"get_quotes": "Submit",
	"cancel": "Cancel",
	"feedback_tx1": "we value your feedback",
	"feedback_tx2": "Please complete the following form and help us to improve our user experience",
	"feedback_tx3": "Tell us how we can improve",
	"feedback_tx4": "Please input Tell us how we can improve",
	"feedback_tx5": "Send Feedback",
	"feedback_tx6": "Thanks for your feedback",
	"feedback_tx7": "We are working hard to continue proving better experience for our users. You have helped us along that process!",
	"feedback_tx8": "Please enter your email",
	"back_home": "Back To Home",
	"footer_tx1": "WAKOOL TRANSPORT",
	"footer_tx2": "Powered by TruckXi",
	"arizona": "Arizona",
	"georgia": "Georgia",
	"new_jersey": "New Jersey",
	"nevada": "Nevada",
	"california": "California",
	"illinois": "Illinois",
	"new_york": "New York",
	"texas": "Texas",
	"washington": "Washington",
	"virginia": "Virginia",
	"maryland": "Maryland",
	"footer_all_tx1": "Your Transport, Shipping, And Logistics Partner",
	"footer_all_title": "Transportation Regulatory Identification Numbers",
	"footer_all_mc": "MC Number",
	"footer_all_dot": "DOT Number",
	"footer_all_ca": "CA Number",
	"footer_all_head": "HEAD QUARTER",
	"footer_wakool_head": "Wakool Headquarters",
	"footer_all_phone": "Phone",
	"footer_all_email": "Email",
	"footer_all_address": "Address: 19130 San Jose Ave, Rowland Heights, CA 91748",
	"footer_all_address_top": "Address: 19130 San Jose Ave, ",
	"footer_all_address_bottom": "Rowland Heights, CA 91748",
	"footer_all_taiwan": "TAIWAN OFFICE",
	"footer_all_taiwan_branch": "Taiwan Branch",
	"footer_all_china_branch": "China Branch",
	"footer_all_china_branch_desc_one": "Room 2523, Trinity Tower, No 575 Wusong Road,",
	"footer_all_china_branch_desc_two": "HongKou District, Shanghai, China",
	"footer_all_sec": "27F, No.236 Sec 1,",
	"footer_all_rd": "Shizheng N 2nd Rd,",
	"footer_all_xitun": "Xitun District, Taichung, Taiwan",
	"footer_all_service": "SERVICE AREAS",
	"footer_all_working": "Working Hours",
	"footer_all_fri": "Mon - Fri: Open 24 hours",
	"footer_all_sat": "Sat: 12 AM (PST) to 4 PM (PST)",
	"footer_all_sunday": "Sun: 4 PM (PST) to 12 AM (PST) Next day",
	"footer_all_social": "SOCIAL MEDIA",
	"instant_port": "PORT / DRAYAGE",
	"instant_ware": "WAREHOUSE",
	"instant_service": "SERVICE",
	"instant_ltl": "LTL",
	"instant_ftl": "FTL",
	"instant_fba": "FBA",
	"instant_walmart_label": "AMAZON/WALMART",
	"instant_walmart": "WALMART",
	"instant_quote": "Get an Instant Quote",
	"instant_fba_quote": "Get an instant quote",
	"instant_fba_please": "Please",
	"instant_fba_contact": "Contact us",
	"instant_fba_information": "for more information of the services. No signup required.",
	"instant_fba_thank": "Thank you!",
	"instant_fba_desired": "Please select your desired service",
	"amazon_FBA": "Amazon FBA Service",
	"walmart_delivery": "Walmart Delivery",
	"select_service": "Select Service",
	"fba_select_location": "Select Location",
	"instant_fba_ocean": "Ocean Container Direct Delivery",
	"new_york_or_new_jersey": "New York / New Jersey",
	"instant_fba_trans": "Transload",
	"instant_fba_loose": "Loose Pallets",
	"instant_fba_back": "Go Back",
	"instant_fba_last_page": "Last Page",
	"instant_warehouse_quote": "Get an instant quote",
	"instant_warehouse_please": "Please",
	"instant_warehouse_contact": "Contact us",
	"instant_warehouse_information": "for more information of the services. No signup required.",
	"instant_warehouse_thank": "Thank you!",
	"warehouse_location": "Warehouse Location",
	"select_location": "Select Location",
	"instant_warehouse_los_angeles": "Los Angeles",
	"instant_warehouse_new_jersey": "New Jersey",
	"instant_warehouse_oak_land": "Oak Land",
	"instant_warehouse_seattle": "Seattle",
	"instant_warehouse_houston": "Houston",
	"instant_warehouse_baltimore": "Baltimore",
	"instant_warehouse_savannah": "Savannah",
	"instant_warehouse_chicago": "Chicago",
	"instant_warehouse_back": "Go Back",
	"instant_otr_quote": "GET AN INSTANT QUOTE",
	"instant_otr_rates": "Rates update frequently to reflect market conditions and aren’t guaranteed until you book.",
	"instant_otr_from": "From",
	"instant_otr_to": "To",
	"instant_otr_pick": "Pick-up Date",
	"instant_otr_drop": "Drop-off Date",
	"instant_otr_back": "Contact to Book",
	"instant_dra_port": "Port",
	"instant_dra_rail": "Rail",
	"instant_dra_los": "Los Angeles / Long Beach",
	"instant_dra_sava": "Savannah",
	"instant_dra_nj": "NJ/ NYC",
	"instant_dra_hou": "Houston",
	"instant_dra_sea": "Seattle",
	"instant_dra_tacoma": "Tacoma",
	"instant_dra_norfolk": "Norfolk VA",
	"instant_dra_baltim": "Baltimore",
	"instant_dra_char": "Charleston",
	"instant_dra_tampa": "Tampa",
	"instant_dra_jack": "Jacksonville",
	"instant_dra_miami": "Miami",
	"instant_dra_mobile": "Mobile",
	"instant_dra_boston": "Boston",
	"instant_dra_oakland": "Oakland",
	"instant_dra_vancouver": "Vancouver, Canada",
	"instant_dra_toronto": "Toronto, Canada",
	"instant_dra_chicago": "Chicago",
	"instant_dra_dallas": "Dallas",
	"instant_dra_atlanta": "Atlanta",
	"instant_dra_kansas": "Kansas",
	"instant_dra_zip": "ZIP code or city name",
	"instant_dra_infomation": "Please fill your information to get flat rate shipping quotes. No signup required.",
	"instant_dra_trans": "Transport Type",
	"instant_dra_pick": "Pick-up Location",
	"instant_dra_drop": "Drop-off Location",
	"instant_dra_input": "please input ZIP code or city name",
	"instant_war_thank": "Thanks for your interest",
	"instant_war_soon": "We are working on this service and it wll be coming soon",
	"quotes_see": "Enter your Information to get quote and book a load",
	"quotes_privacy": "Privacy Notice",
	"quotes_volume": "Please choose Monthly Volume",
	"quotes_address": "Please input Email Address",
	"quote_sc_pick": "Pick it up from",
	"quote_sc_delivery": "Delivery to",
	"quote_sc_truck": "truck",
	"quote_sc_included": "Included Fuel Charges",
	"special_rates": "Wakool Transport offers special rates for monthly volumes of 5 or more containers.",
	"quote_sc_valid": "Valid from",
	"quote_sc_ctf": "CTF",
	"quote_sc_pier": "Pier Pass",
	"quote_sc_terminal": "Terminal Congestion Fee",
	"quote_sc_nights": "Nights and Weekend Pull",
	"quote_sc_drop": "Drop fee",
	"detention": "Detention",
	"quote_sc_special": "Special Instruction",
	"quote_sc_round": "Estimated Round Trip",
	"quote_sc_miles": "Miles",
	"quote_sc_thanks": "Thanks for submitting",
	"quote_sc_member": "Thank you for your interest. A member of our team will be in touch shortly.",
	"home_los": "Los Angeles / Long Beach Port",
	"home_sava": "Savannah Port",
	"home_hous": "Houston Port",
	"home_jersey": "New Jersey Port",
	"home_angeles": "Los Angeles RailRoad",
	"home_rail": "Dallas RailRoad",
	"instant_otr_vehicle_type": "Vehicle Type",
	"instant_otr_vehicle_type_dry": "Dry",
	"instant_otr_vehicle_type_refrigerated": "Refrigerated",
	"instant_otr_vehicle_type_flatbed": "Flatbed",
	"commodity": "Commodity",
	"weight": "Weight",
	"monthly_volume": "Monthly Volume",
	"reminder_note": "Reminder/Note",
	"accessorial_charges": "Accessorial Charges",
	"additional_charges": "Additional charges (if applicable)",
	"storage_fee_policy": "Storage fee policy",
	"empty_return_policy": "Empty return policy",
	"additional_charge_desc": "Additional charges that may be incurred will be collected only if they incurred."
}

export default data;