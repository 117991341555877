import React from 'react'
import { useSelector } from "react-redux";
import './index.scss'
import './mobile.scss'
import bannerImg from "@/assets/images/blogDetail/banner.png";
import userViewsIcon from "@/assets/images/icon/user-views.png";
import BlogArticles from '@/components/BlogArticles'
import { useTranslation } from 'react-i18next';

export default function BlogDetail() {
  const commonState = useSelector(state => state.common)
  const { t } = useTranslation()
  const rootClassName = commonState.isMobile ? 'blog-mobile-detail-container' : 'blog-detail-container'

  return (
    <div className={rootClassName}>
      <div className={'blog-detail-top-container'}>
        <div className={'blog-detail-top-img-container'}>
          <img src={bannerImg} alt="" />
        </div>
        <div className={'blog-detail-top-info-container'}>
          <div className={'blog-detail-top-info-left-container'}>
            <div className={'blog-detail-top-info-left-icon-container'}>
              <img src={userViewsIcon} alt="" />
            </div>
            <div className={'blog-detail-top-info-left-text'}>888 views</div>
          </div>
          <div className={'blog-detail-top-info-date'}>18/03/2023</div>
        </div>
        <div className={'blog-detail-top-title'}>
          {t('freight')}
        </div>
        <div className={'blog-detail-top-des-container'}>
          <div>
            <a href="/" target={'_blank'}>{t('uber')}</a>
            &nbsp;
            {t('plans_deploy')}</div>
          <div className={'blog-detail-top-des-margin-bottom-1'}>
            {t('invoice_uber')}
            &nbsp;
            <a href="/" target={'_blank'}>WattEV</a>
            {t('heavy_ev')}
          </div>
          <div className={'blog-detail-top-des-margin-bottom-1'}>
            {t('first_shipper')}
            &nbsp;
            <a href="/" target={'_blank'}>CHEP</a>
            {t('global_leader')}
          </div>
          <div className={'blog-detail-top-des-margin-bottom-1'}>
            {t('pillot_builds')}
          </div>
          <div className={'blog-detail-top-des-margin-bottom-1'}>
            {t('chep_schedule')}
          </div>
          <div className={'blog-detail-top-des-margin-bottom-1'}>
            {t('blog_detail_addition')}
          </div>
          <div>{t('blog_detail_milestone')}</div>
          <div className={'blog-detail-top-des-margin-bottom-1'}>
            “{t('blog_detail_electric')}
            &nbsp;
            <a
              href="/"
              target={'_blank'}>
              {t('blog_detail_frankiv')}
            </a>
            .
          </div>
          <div className={'blog-detail-top-des-bold blog-detail-top-des-margin-bottom-1'}>{t('blog_detail_profoundly')}</div>
          <div className={'v'}>{t('blog_detail_added')}</div>
          <div className={'blog-detail-top-des-margin-bottom-1'}>{t('blog_detail_says')}</div>
          <div>{t('blog_detail_partnering')}</div>
          <div className={'blog-detail-top-des-margin-bottom-1'}>
            <a href="/" target={'_blank'}>{t('marisa')}</a>
            {t('blog_detail_Director')}</div>
          <div className={'blog-detail-top-des-margin-bottom-1'}>
            {t('blog_detail_Director2')}
            &nbsp;
            <a href="/" target={'_blank'}>{t('salim')}</a>
            {t('blog_detail_Director3')}</div>
          <div>{t('blog_detail_Director4')}</div>
        </div>
        <BlogArticles
          data={[1, 2, 3, 4, 5, 6]}
          title={t('top_v_ar')}
        />
      </div>
    </div>
  )
}
