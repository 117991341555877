import img1 from '@/assets/images/headers/facebook.png';
import img2 from '@/assets/images/headers/in.png';
import img3 from '@/assets/images/headers/instagram.png';
import companyImg from '@/assets/images/headers/company.png';
import { Button, Dropdown, Menu, Modal, } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import './index.scss';
import './mobile.scss';

import eventBus from '@/common/EventBus';
import Feedback from '@/components/Feedback';
import GetAnInstantCode from '@/components/GetAnInstantCode';
import {
  FACEBOOK_LINK_URL,
  HEADER_GET_AN_INSTANT_CODE_EVENT_NAME,
  INSTAGRAM_LINK_URL,
  LINKED_IN_LINK_URL
} from '@/constants';
import { languageChangeAction } from '@/redux/actions';
import { changeLanguage } from '@/utils/index';
import { useTranslation } from 'react-i18next';
import {
  TranslationOutlined
} from '@ant-design/icons';

const defaultProps = {
  onGetAnInstantCode: () => {
  },
};

const Header = (props) => {
  const { t } = useTranslation()

  const options = Object.assign({}, defaultProps, props);

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const commonState = useSelector(state => state.common)
  const languageName = useSelector(state => state.user.languageName)
  const rootClassName = commonState.isMobile ? 'header-mobile-main' : 'header-main'
  const getAnInstantCodeModalName = commonState.isMobile ? 'header-mobile-get-an-instant-code-modal' : 'header-get-an-instant-code-modal'

  const feedbackRef = useRef(null)
  //Get an Instant Quote相关
  const [isModalOpen, setIsModalOpen] = useState(false);


  const array = [
    {
      imgUrl: img1,
      path: FACEBOOK_LINK_URL
    },
    {
      imgUrl: img2,
      path: LINKED_IN_LINK_URL
    },
    {
      imgUrl: img3,
      path: INSTAGRAM_LINK_URL
    },
  ]
  const array2 = [
    {
      text: t('home'),
      path: '/home'
    },
    {
      text: t('quote'),
      path: '/getQuote'
    },
    {
      text: t('feedback'),
      path: '/feedback'
    },
    {
      text: t('contact'),
      path: '/contact'
    }
  ]
  const array3 = [
    {
      text: 'HOME',
      path: '/home'
    },
    {
      text: 'BLOG',
      path: '/blog'
    },
    {
      text: 'CONTACT US',
      path: '/contact'
    }
  ]

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleGetAnInstantCode = () => {
    showModal()
    options.onGetAnInstantCode()
  }
  const handleHeaderGetAnInstantCodeEvent = () => {
    showModal()
  }
  const navigatePage = (path) => {
    if (path === '/home') {
      return window.location.href = 'https://www.wakooltransport.com/'
    }
    if (path === '/getQuote') {
      return window.location.href = 'https://rates.wakooltransport.com/home'
    }
    if (path === '/feedback') {
      feedbackRef.current?.showModal()
      return ''
    }
    navigate(path)
  }

  // 修改语言
  const languageChange = (value) => {
    if (!value || value == null) {
      return
    }
    const dt = {
      languageValue: value,
      languageName: value == 'en' ? 'English' : value == 'zh_cn' ? '中文(简体)' : '中文(繁體)',
    }
    dispatch(languageChangeAction(dt));
    changeLanguage(value)
  }

  const menu = (
    <Menu>
      <Menu.Item>
        <Button onClick={() => { languageChange('en') }}>{t('language_en')}</Button>
      </Menu.Item>
      <Menu.Item>
        <Button onClick={() => { languageChange('zh_cn') }}>{t('language_zh_cn')}</Button>
      </Menu.Item>
      <Menu.Item>
        <Button onClick={() => { languageChange('zh_tw') }}>{t('language_zh_tw')}</Button>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    // 获取本地语言
    const language = localStorage.getItem('wakool_language_value');
    if (language) {
      languageChange(language);
    } else {
      languageChange('zh_cn');
    }
    eventBus.addEventListener(HEADER_GET_AN_INSTANT_CODE_EVENT_NAME, handleHeaderGetAnInstantCodeEvent)
    return () => {
      eventBus.removeEventListener(HEADER_GET_AN_INSTANT_CODE_EVENT_NAME, handleHeaderGetAnInstantCodeEvent)
    }
  }, [])

  return (
    <div className={rootClassName}>
      <div className="header-main-header-container">
        <img src={companyImg} alt="" />
        <div className="header-main-header-main">
          {/* <div className="header-main-header-main-left">
            <ul className="header-main-header-main-left-ul">
              {array.map((item, index) =>
                <li key={'array' + index} className="header-main-header-main-left-ul-li">
                  <img src={item.imgUrl} alt="" onClick={() => item.path && window.open(item.path)} />
                </li>)}
            </ul>
          </div> */}
          <div className="header-main-header-main-right">
            <ul className="header-main-header-main-right-ul">
              <li key={'langulage'} className="header-main-header-main-right-ul-li">
                <Dropdown overlay={menu} placement="bottomLeft">
                  <Button><TranslationOutlined style={{fontSize: commonState.isMobile ? '10px' : '15px', marginRight: commonState.isMobile ? '-5px' : 0}}/>{languageName}</Button>
                </Dropdown>
              </li>
              {array2.map((item, index) =>
                <li key={'array2' + index}
                  className="header-main-header-main-right-ul-li"
                  onClick={() => navigatePage(item.path)}
                >
                  {item.text}
                </li>)}
            </ul>
          </div>
        </div>
      </div>
      <Modal
        open={isModalOpen}
        footer={null}
        maskClosable={false}
        onCancel={handleCancel}
        className={getAnInstantCodeModalName}
      >
        <GetAnInstantCode onItemClick={handleCancel} showGetAnInstantCodeModal={showModal} />
      </Modal>
      <Feedback ref={feedbackRef} />
    </div>
  )
}
export default Header
