import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import './index.scss';
import './mobile.scss';

import facebookImg from '@/assets/images/contact/facebook.png';
import linkedInImg from '@/assets/images/contact/linkedIn.png';
import twitterImg from '@/assets/images/contact/twitter.png';
import xiaoHongShuImg from '@/assets/images/contact/xiaoHongShu.png';

import wechatNewsQrCodeImg from '@/assets/images/home/wechat-news-qr-code-new.jpg';
import wechatQrCodeImg from '@/assets/images/home/wechat-qr-code-new.png';
import wechatOfficialAccount from '@/assets/images/home/wechatOfficialAccount.png'
import wechatLogo from '@/assets/images/home/wechatLogo.png'

import { FACEBOOK_LINK_URL, LINKED_IN_LINK_URL, TWITTER_LINK_URL, XIAO_HONG_SHU_LINK_URL } from '@/constants';
import { useTranslation } from 'react-i18next';

const array2 = [
  {
    imgUrl: facebookImg,
    path: FACEBOOK_LINK_URL
  },
  {
    imgUrl: twitterImg,
    path: TWITTER_LINK_URL
  },
  {
    imgUrl: linkedInImg,
    path: LINKED_IN_LINK_URL
  },
  {
    imgUrl: xiaoHongShuImg,
    path: XIAO_HONG_SHU_LINK_URL
  },
]


const Footer = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const commonState = useSelector(state => state.common)
  const isEn = useSelector(state => state.user.languageValue == 'en')
  const rootClassName = commonState.isMobile ? 'footerAll-mobile-main' : 'footerAll-main'
  const qrCodeInfoList = [
    {
      title: t('wechat_official_account'),
      img: wechatNewsQrCodeImg,
      logo: wechatOfficialAccount
    },
    {
      title: t('wechat_contact'),
      img: wechatQrCodeImg,
      logo: wechatLogo
    }
  ]
  const array1 = [
    {
      text: t('arizona'),
    },
    {
      text: t('georgia'),
    },
    {
      text: t('new_jersey'),
    },
    {
      text: t('nevada'),
    },
    {
      text: t('california'),
    },
    {
      text: t('illinois'),
    },
    {
      text: t('new_york'),
    },
    {
      text: t('texas'),
    },
    {
      text: t('washington'),
    },
    {
      text: t('virginia'),
    },
    {
      text: t('maryland'),
    }
  ]
  return (
    <div className={rootClassName}>
      <div className="footerAll-main-left">
        <img className="footerAll-main-left-logo" src={require('@/assets/images/headers/logoNew.png')} alt="" />
        <h1 className="footerAll-main-left-h">{t('footer_wakool_head')}</h1>
        <div>
          <p className="footerAll-main-left-p">{t('footer_all_phone')}: 626-723-3100</p>
          <p className="footerAll-main-left-p">
            {t('footer_all_email')}:
            <span className="footerAll-main-left-uspan" onClick={() => navigate('#')}> wkt@wakooltransport.com / rates@wakooltransport.com</span>
          </p>
          <p className="footerAll-main-left-p">{t('footer_all_address_top')}<br/>{t('footer_all_address_bottom')}</p>
        </div>
        <h1 className="footerAll-main-left-h">{t('footer_all_taiwan_branch')}</h1>
        {isEn ?
          <div>
            <p className="footerAll-main-left-p">{t('footer_all_sec')}{t('footer_all_rd')}</p>
            {/* <p className="footerAll-main-left-p">{t('footer_all_rd')}</p> */}
            <p className="footerAll-main-left-p">{t('footer_all_xitun')}</p>
          </div> :
          <div>
            <p className="footerAll-main-left-p">{t('footer_all_xitun')}{t('footer_all_rd')}</p>
            {/* <p className="footerAll-main-left-p"></p> */}
            <p className="footerAll-main-left-p">{t('footer_all_sec')}</p>
          </div>
        }
        <h1 className="footerAll-main-left-h">{t('footer_all_china_branch')}</h1>
        <div>
          <p className="footerAll-main-left-p">{t('footer_all_china_branch_desc_one')}</p>
          { localStorage.getItem('wakool_language_value') === 'en' && <p className="footerAll-main-left-p">{t('footer_all_china_branch_desc_two')}</p>}
        </div> 
      </div>
      <div className="footerAll-main-right">
        <h1 className="footerAll-main-right-h">{t('footer_all_title')}</h1>
        <div>
          <p className="footerAll-main-right-p">{t('footer_all_mc')}: 988495</p>
          <p className="footerAll-main-right-p">{t('footer_all_dot')}: 2928289</p>
          <p className="footerAll-main-right-p">{t('footer_all_ca')}: 492086</p>
        </div>
        {/* <h1 className="footerAll-main-right-h">
          {t('footer_all_service')}
        </h1>
        <ul className="footerAll-main-right-ul1">
          {array1.map((item, index) =>
            <li key={'array1' + index}
              className="header-main-header-main-right-ul-li"
            >
              {item.text}
            </li>)}
        </ul> */}
        <h1 className="footerAll-main-right-h">{t('footer_all_working')}</h1>
        <p className="footerAll-main-right-p">{t('footer_all_fri')}</p>
        <p className="footerAll-main-right-p">{t('footer_all_sat')}</p>
        <p className="footerAll-main-right-p">{t('footer_all_sunday')}</p>
        {/* <h1 className="footerAll-main-right-h footerAll-main-right-h3">{t('footer_all_social')}</h1>
        <ul className="footerAll-main-right-ul2">
          {array2.map((item, index) =>
            <li key={'array2' + index} className="header-main-header-main-right-ul-li">
              <img src={item.imgUrl} alt="" onClick={() => item.path && window.open(item.path)} />
            </li>)}
        </ul> */}
        <div className={'footerAll-main-right-qr-code-info-container'}>
          {
            qrCodeInfoList.map((item, index) => (
              <div
                className={'footerAll-main-right-qr-code-info-item-container'}
                key={'qr-code-info-list-' + index}>
                <div className={'footerAll-main-right-qr-code-info-item-title'}>{item.title}</div>
                <div className={'footerAll-main-right-qr-code-info-item-logo'}> <img src={item.logo} alt="" /></div>
                <div className={'footerAll-main-right-qr-code-info-item-img-container'}>
                  <img src={item.img} alt="" />
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}
export default Footer
