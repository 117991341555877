import React from 'react'
import { useSelector } from 'react-redux'
import './index.scss'
import './mobile.scss'
import bannerImg from '@/assets/images/blog/banner.png'
import topViewAvatarImg from '@/assets/images/blog/topViewAvatar.png'
import { useNavigate } from "react-router-dom";
import BlogArticles from '@/components/BlogArticles'
import { useTranslation } from 'react-i18next'

export default function Blog() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const commonState = useSelector(state => state.common)

  const topViewList = [1, 2, 3]
  const moreArticlesList = [1, 2, 3, 4, 5, 6, 7, 8, 9]

  const handelTopViewItemClick = (item, index) => {
    console.log(item, index);
    navigate('/blogDetail')
  }
  const renderTopViewItem = (item, index) => (
    <div
      onClick={() => handelTopViewItemClick(item, index)}
      className={'blog-top-view-item-container'}
      key={'top_view_' + index}>
      <div className={'blog-top-view-item-img-container'}>
        <img src={topViewAvatarImg} alt="" />
      </div>
      <div className={'blog-top-view-item-des'}>{t('blog_tx1')}</div>
    </div>
  )

  const handleMoreArticlesItemClick = (item, index) => {
    console.log(item, index);
    navigate('/blogDetail')
  }
  const handleBlogArticlesBtnClick = () => {
    console.log('handleBlogArticlesBtnClick');
  }

  const rootClassName = commonState.isMobile ? 'blog-mobile-container' : 'blog-container'

  return (
    <div className={rootClassName}>
      <div className={'blog-top-container'}>
        <div className={'blog-top-left-container'}>
          <div className={'blog-banner-container'}>
            <img src={bannerImg} alt="banner" />
          </div>
          <div className={'blog-top-left-des'}>
            {t('more_sales')}
          </div>
        </div>
        <div className={'blog-top-view-container'}>
          <div className={'blog-top-view-title'}>{t('top_v')}</div>
          {topViewList.map(renderTopViewItem)}
        </div>
      </div>
      <BlogArticles
        data={moreArticlesList}
        btnText={'Show More'}
        onBtnClick={handleBlogArticlesBtnClick}
        onItemClick={(item, index) => { handleMoreArticlesItemClick(item, index) }}
      />
    </div>
  )
}
