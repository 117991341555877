import React from 'react'
// import './index.scss';
// import './mobile.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";

import lineQrCodeImg from '@/assets/images/home/line-qr-code.png';
import wechatNewsQrCodeImg from '@/assets/images/home/wechat-news-qr-code.jpg';
import wechatQrCodeImg from '@/assets/images/home/wechat-qr-code.png';
import whatsAppQrCodeImg from '@/assets/images/home/whatsapp-new-qr-code.png';
import wechatOfficialAccount from '@/assets/images/home/wechatOfficialAccount.png';
import wechatLogo from '@/assets/images/home/wechatLogo.png';
import linkLogo from '@/assets/images/home/lineLogo.png';
import whatsapp from '@/assets/images/home/whatsapp.png';
import facebook from '@/assets/images/home/facebook.png';
import ins from '@/assets/images/home/ins.png';
import linkedin from '@/assets/images/home/linkedin.png';
import redbook from '@/assets/images/home/redBook.png';
import { FACEBOOK_LINK_URL, INSTAGRAM_LINK_URL, LINKED_IN_LINK_URL, XIAO_HONG_SHU_LINK_URL  } from '@/constants';
import { Tooltip } from 'antd';

const FooterLogoList = () => {
  const commonState = useSelector(state => state.common)
  const { t } = useTranslation()
  const rootClassName = commonState.isMobile ? 'footer-logo-mobile-main' : 'foooter-logo-main'
  const iconInfoList = [
    {
      title: t('wechat_official_account'),
      img: wechatOfficialAccount,
      qrcodeImg: wechatNewsQrCodeImg
    },
    {
      title: t('wechat'),
      img: wechatLogo,
      qrcodeImg:  wechatQrCodeImg
    },
    {
      title: t('line_official_account'),
      img: linkLogo,
      qrcodeImg: lineQrCodeImg
    },
    {
      title: t('whats_app'),
      img: whatsapp,
      qrcodeImg: whatsAppQrCodeImg
    },
    {
      title: t('facebook'),
      img: facebook,
      path: FACEBOOK_LINK_URL
    },
    {
      title: t('instagram'),
      img: ins,
      path: INSTAGRAM_LINK_URL
    },
    {
      title: t('linkedin'),
      img: linkedin,
      path: LINKED_IN_LINK_URL
    },
    {
      title: t('redbook'),
      img: redbook,
      path: XIAO_HONG_SHU_LINK_URL
    }
  ]

  return (
    <div className={'home-bottom-container'}>
      {
        iconInfoList.map((item, index) => (
          <div
            className={'home-bottom-item-container'}
            key={'appIcon-info-list-' + index}>
            <div className={'home-bottom-item-title'}><div className={'home-bottom-item-title-text'}>{item.title}</div></div>
            <Tooltip color='white' title={item.qrcodeImg && <img src={item.qrcodeImg} style={{width: commonState.isMobile ? '50px': '100px', height: commonState.isMobile ? '50px' : '100px'}}/>} trigger="click">
              <div className={'home-bottom-item-img-container'}>
                <img src={item.img} alt="" onClick={() => item.path && window.open(item.path)}/>
              </div>
            </Tooltip>
          </div>
        ))
      }
    </div>
  )
}
export default FooterLogoList
