export const GET_AN_INSTANT_CODE_OTR_KEY = 'OTR'
export const GET_AN_INSTANT_CODE_PORT_RAIL_DRAYAGE_KEY = 'portRailDrayage'
export const GET_AN_INSTANT_CODE_LTL_KEY = 'LTL'
export const GET_AN_INSTANT_CODE_WAREHOUSE_KEY = 'warehouse'
export const GET_AN_INSTANT_CODE_FBA_KEY = 'FBA'
export const GET_AN_INSTANT_CODE_WALMART_KEY = 'Walmart'
export const GET_AN_INSTANT_CODE_ALL_IN_RATES_KEY = 'allInRates'
export const GET_QUOTES_MONTHLY_VOLUME_1_VAL = '1-5'
export const GET_QUOTES_MONTHLY_VOLUME_LIST = [
  {
    label: '1-5',
    value: GET_QUOTES_MONTHLY_VOLUME_1_VAL
  },
  {
    label: '5+',
    value: '5+'
  },
  {
    label: '10+',
    value: '10+'
  },
  {
    label: '20+',
    value: '20+'
  },
  {
    label: '50+',
    value: '50+'
  },
  {
    label: '100+',
    value: '100+'
  },
]
export const GET_QUOTES_SUCCESS_TYPE_1_KEY = 'getQuotesSuccessType1'
export const GET_QUOTES_SUCCESS_TYPE_2_KEY = 'getQuotesSuccessType2'
export const HEADER_GET_AN_INSTANT_CODE_EVENT_NAME = 'headerGetAnInstantCodeEvent'
export const FACEBOOK_LINK_URL = 'https://www.facebook.com/WakoolTransport/'
export const TWITTER_LINK_URL = ''
export const LINKED_IN_LINK_URL = 'https://www.linkedin.com/company/wakool-transport'
export const XIAO_HONG_SHU_LINK_URL = 'http://xhslink.com/ExUdRr'
export const INSTAGRAM_LINK_URL = 'https://www.instagram.com/wakooltransport/'

export const DATE_FORMAT_TEMPLATE = 'YYYY-MM-DD'
