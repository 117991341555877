import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import './index.scss'
import './mobile.scss'

import { sendFeedback } from '@/api';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

const defaultProps = {
  onModalCancel: () => {
  },
};
const Feedback = forwardRef(function Feedback(props, ref) {
  const options = Object.assign({}, defaultProps, props);

  const navigate = useNavigate()
  const { t } = useTranslation()

  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'feedback-mobile-modal' : 'feedback-modal'

  const [isFeedbackSuccess, setIsFeedbackSuccess] = useState(false)
  const [isShow, setIsShow] = useState(false)
  const showModal = () => {
    setIsShow(true)
  }
  const hideModal = () => {
    setIsShow(false)
  }

  const formRef = useRef(null);

  const handleSendFeedbackBtnClick = () => {
    formRef.current?.submit()
  }
  const onFormFinish = values => {
    const params = {
      Email_Address: values.emailAddress,
      Message: values.tellUsHowWeCanImprove,
    }
    sendFeedback(params)
      .then(() => {
        setIsFeedbackSuccess(true)
      })
  }

  const handleModalCancel = () => {
    options.onModalCancel()
    formRef.current?.resetFields()
    isFeedbackSuccess && setIsFeedbackSuccess(false)
    hideModal()
  }

  useImperativeHandle(ref, () => ({
    showModal,
  }))

  const renderFeedback = () => {
    return <div className={'feedback-container'}>
      <div className={'feedback-title'}>{t('feedback_tx1')}</div>
      <div className={'feedback-des'}>
        {t('feedback_tx2')}
      </div>
      <Form
        className={'feedback-form-container'}
        ref={formRef}
        layout={'vertical'}
        requiredMark={false}
        onFinish={onFormFinish}
        autoComplete="off">
        <Form.Item
          label={<span className='formLabel'>{t('email_address')}</span>}
          name="emailAddress"
          rules={[
            {
              required: true,
              message: t('feedback_tx8'),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={<span className='formLabel'>{t('feedback_tx3')}</span>}
          name="tellUsHowWeCanImprove"
          rules={[
            {
              required: true,
              message: t('feedback_tx4'),
            },
          ]}>
          <TextArea
            autoSize={{
              minRows: 9,
              maxRows: 9
            }}
          />
        </Form.Item>
      </Form>
      <Button
        className={'feedback-btn-1'}
        onClick={handleSendFeedbackBtnClick}>
        {t('feedback_tx5')}
      </Button>
      <Button
        className={'feedback-btn-2'}
        type="link"
        onClick={handleModalCancel}>
        {t('cancel')}
      </Button>
    </div>
  }

  const handleBackToHome = () => {
    handleModalCancel()
    navigate('/')
  }
  const renderFeedbackSuccess = () => {
    return <div className={'feedback-success-container'}>
      <div className={'feedback-success-title'}>
        {t('feedback_tx6')}
      </div>
      <div className={'feedback-success-des'}>
        {t('feedback_tx7')}
      </div>
      <Button className={'feedback-success-btn'} onClick={handleBackToHome}>{t('back_home')}</Button>
    </div>
  }

  return (
    <Modal
      open={isShow}
      footer={null}
      maskClosable={false}
      onCancel={handleModalCancel}
      className={rootClassName}>
      {isFeedbackSuccess ? renderFeedbackSuccess() : renderFeedback()}
    </Modal>
  )
})

export default Feedback
