import FootersAll from '@/components/FootersAll';
import { Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import './index.scss';
import './mobile.scss';

import lineQrCodeImg from '@/assets/images/home/line-qr-code.png';
import wechatNewsQrCodeImg from '@/assets/images/home/wechat-news-qr-code.jpg';
import wechatQrCodeImg from '@/assets/images/home/wechat-qr-code.png';
import whatsAppQrCodeImg from '@/assets/images/home/whats-app-qr-code.png';
import wechatOfficialAccount from '@/assets/images/home/wechatOfficialAccount.png';
import wechatLogo from '@/assets/images/home/wechatLogo.png';
import linkLogo from '@/assets/images/home/lineLogo.png';
import whatsapp from '@/assets/images/home/whatsapp.png';
import facebook from '@/assets/images/home/facebook.png';
import ins from '@/assets/images/home/ins.png';
import linkedin from '@/assets/images/home/linkedin.png';
import redbook from '@/assets/images/home/redBook.png';
import { FACEBOOK_LINK_URL, INSTAGRAM_LINK_URL, LINKED_IN_LINK_URL, XIAO_HONG_SHU_LINK_URL  } from '@/constants';
import FooterLogoList from '@/components/FooterLogoList'

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid Email Address!',
  },
};
/* eslint-enable no-template-curly-in-string */

const onFinish = (values) => {
  console.log(values);
};

const Contact = () => {
  const commonState = useSelector(state => state.common)
  const { t } = useTranslation()
  const rootClassName = commonState.isMobile ? 'contact-mobile-main' : 'contact-main'
  const iconInfoList = [
    {
      title: t('wechat_official_account'),
      img: wechatOfficialAccount,
      qrcodeImg: wechatNewsQrCodeImg
    },
    {
      title: t('wechat'),
      img: wechatLogo,
      qrcodeImg:  wechatQrCodeImg
    },
    {
      title: t('line_official_account'),
      img: linkLogo,
      qrcodeImg: lineQrCodeImg
    },
    {
      title: t('whats_app'),
      img: whatsapp,
      qrcodeImg: whatsAppQrCodeImg
    },
    {
      title: t('facebook'),
      img: facebook,
      path: FACEBOOK_LINK_URL
    },
    {
      title: t('instagram'),
      img: ins,
      path: INSTAGRAM_LINK_URL
    },
    {
      title: t('linkedin'),
      img: linkedin,
      path: LINKED_IN_LINK_URL
    },
    {
      title: t('redbook'),
      img: redbook,
      path: XIAO_HONG_SHU_LINK_URL
    }
  ]
  const wakoolLanguage = localStorage.getItem('wakool_language_value') 
  return (
    <div className={rootClassName}>
      <h1>{t('our_locations')}</h1>
      <img src={require('@/assets/images/home/newMap2.jpg')} alt="" />
      <img src={require(wakoolLanguage === 'en' ? '@/assets/images/contact/banner2New.png' : wakoolLanguage === 'zh_cn' ? '@/assets/images/contact/address-zh-cn.png' : '@/assets/images/contact/address-zh-tw.png')} alt="" />
      <FootersAll />
      <img src={require(wakoolLanguage === 'en' ? '@/assets/images/contact/banner3New.png' : wakoolLanguage === 'zh_cn' ? '@/assets/images/contact/office_zh-cn.png' : '@/assets/images/contact/office_zh-tw.png')} alt="" />
      <div className="contact-main-form">
        <h1>{t('leave_us_message')}</h1>
      </div>
      <Form
        layout="vertical"
        name="nest-messages"
        onFinish={onFinish}
        className="form-main"
        style={{
          maxWidth: 620,
          marginLeft: 'auto',
          marginRight: 'auto'
        }}
        validateMessages={validateMessages}
        requiredMark={false}
      >
        <div className='display-center'>
          <Form.Item
            name={['message', 'firstName']}
            label={t('first_name')}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['message', 'lastName']}
            label={t('last_name')}
          >
            <Input />
          </Form.Item>
        </div>
        <Form.Item
          name={['message', 'emailAddress']}
          label={t('email_address')}
          rules={[
            {
              required: true,
              type: 'email',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={['message', 'company']}
          label={t('company')}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={['message', 'phoneNumber']}
          label={t('phone_n')}
        >
          <Input />
        </Form.Item>
        <Form.Item name={['message', 'content']} label={t('message')}>
          <Input.TextArea autoSize={{ minRows: 9, maxRows: 5 }} />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit">
            {t('submit')}
          </Button>
        </Form.Item>
      </Form>
      <FooterLogoList />
    </div>
  )
}

export default Contact
