/**
 * redux最核心的管理对象模块
 */

import {legacy_createStore as createStore, applyMiddleware} from 'redux';
import reduxThunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';

import reducers from './reducers';

// 向外暴露store对象
export default createStore(reducers, composeWithDevTools(applyMiddleware(reduxThunk)));
