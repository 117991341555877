import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translation_en from './en.js';
import translation_zh_cn from './zh-cn.js';
import translation_zh_tw from './zh-tw.js';

const resources = {
	zh_cn: {
		translation: translation_zh_cn,
	},
	en: {
		translation: translation_en,
	},
	zh_tw: {
		translation: translation_zh_tw,
	},
};

i18next.use(initReactI18next).init({
	resources,
	// 默认语言  zh_cn  英文
	lng: localStorage.getItem('wakool_language_value') || 'zh_cn',
	interpolation: {
		escapeValue: false,
	},
});

export default i18next;