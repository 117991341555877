import React from 'react'
import './index.scss'
import './mobile.scss'
import userViewsIcon from '@/assets/images/icon/user-views.png'
import moreArticlesImg from '@/assets/images/blog/moreArticles.png'
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next'

const defaultProps = {
  item: {},
  index: 0,
  onClick: () => { },
  className: '',
};

export default function BlogArticlesItem(props) {
  const options = Object.assign({}, defaultProps, props);
  const { t } = useTranslation()
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'blog-mobile-articles-item-container ' : 'blog-articles-item-container '

  return (
    <div
      className={rootClassName + options.className}
      onClick={() => options.onClick(options.item, options.index)}>
      <div className={'blog-articles-item-img-container'}>
        <img src={moreArticlesImg} alt="" />
      </div>
      <div className={'blog-articles-item-info-container'}>
        <div className={'blog-articles-item-info-left-container'}>
          <div className={'blog-articles-item-info-left-icon-container'}>
            <img src={userViewsIcon} alt="" />
          </div>
          <div className={'blog-articles-item-info-left-text'}>{t('views_8')}</div>
        </div>
        <div className={'blog-articles-item-info-date'}>18/03/2023</div>
      </div>
      <div className={'blog-articles-item-des'}>{t('logistic')}</div>
    </div>
  )
}
