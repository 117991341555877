import { Button, Modal, Select, Spin } from 'antd';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getUberFreight } from '@/api';
import { getTodayAfter7Days } from '@/common';
import WGoogleMapsSelect from '@/components/WGoogleMapsSelect';
import { useTranslation } from 'react-i18next';
import './index.scss';
import './mobile.scss';
import dayjs from "dayjs";
import fbaDetailImg from '@/assets/images/GetAnInstantCode/OTRNew.png';

const defaultProps = {
  isShow: false,
  onModalCancel: () => { },
  onModalGoContactToBook: () => { },
};
const arrayMar = [
  {
    title: 'Mar 5',
    price: '',
  },
  {
    title: 'Mar 6',
    price: '',
  },
  {
    title: 'Mar 7',
    price: '',
  },
  {
    title: 'Mar 8',
    price: '',
  },
  {
    title: 'Mar 9',
    price: '',
  },
  {
    title: 'Mar 10',
    price: '',
  },
  {
    title: 'Mar 11',
    price: '',
  },
  {
    title: 'Mar 12',
    price: '',
  },
  {
    title: 'Mar 13',
    price: '',
  },
  {
    title: 'Mar 14',
    price: '1,650',
  },
  {
    title: 'Mar 15',
    price: '',
  },
  {
    title: 'Mar 16',
    price: '',
  },
  {
    title: 'Mar 17',
    price: '',
  },
  {
    title: 'Mar 18',
    price: '',
  },
]

const pickUpDateOptions = getTodayAfter7Days([6, 0], 2).map(item => {
  return {
    label: item,
    value: item,
  }
})
export default function GetAnInstantCodeWarehouse(props) {
  const options = Object.assign({}, defaultProps, props);
  const { t } = useTranslation();
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'getAnInstantCodeOTR-mobile' : 'getAnInstantCodeOTR'
  const [hasError, setHasError] = useState(false);
  const [pickUpLocationData, setPickUpLocationData] = useState(null);
  const [dropOffLocationData, setDropOffLocationData] = useState([]);
  const [pickUpDate, setPickUpDate] = useState(pickUpDateOptions[0].value);
  const [dropOffDateOptions, setDropOffDateOptions] = useState([]);
  const [dropOffDate, setDropOffDate] = useState(undefined);
  const [vehicleType, setVehicleType] = useState('DRY');
  const [loading, setLoading] = useState(false);
  const goContactToBook = () => {
    if (dropOffDate) {
      setHasError(false);
      options.onModalCancel();
      options.onModalGoContactToBook({pickUpLocationData, dropOffLocationData, pickUpDate, dropOffDate, vehicleType});
    } else {
      setHasError(true);
    }
  }
  const cancel = () => {
    options.onModalCancel();
  }
  
  const handlePickUpLocationSelect = (newPickUpLocationData) => {
    setPickUpLocationData(newPickUpLocationData)
  }
  const handleDropOffLocationSelect = (newDropOffLocationData) => {
    setDropOffLocationData(newDropOffLocationData);
  }
  const handlePickUpDateSelect = (newPickUpDate) => {
    setPickUpDate(newPickUpDate);
  }
  const handleDropOffDateSelect = (newDropOffDate) => {
    setHasError(false);
    setDropOffDate(newDropOffDate);
  }
  const debounceGetUberFreight = debounce(() => {
    setLoading(true)
    const params = {
      pickup: {
        address: pickUpLocationData.address,
        city: pickUpLocationData.city,
        state: pickUpLocationData.state,
        zipcode: pickUpLocationData.postcode,
        country: pickUpLocationData.country,
      },
      dropoff: {
        address: dropOffLocationData.address,
        city: dropOffLocationData.city,
        state: dropOffLocationData.state,
        zipcode: dropOffLocationData.postcode,
        country: dropOffLocationData.country,
      },
      date: pickUpDate,
      vehicle_type: vehicleType,
    }
    getUberFreight(params)
      .then(res => {
        const resDataData = res.data.data;
        const newDropOffDateOptions = resDataData.map(item => {
          const { date, price } = item;
          const label = `${date} $${Number(price).toLocaleString()}`;
          return {
            ...item,
            day: new Date(date).getDay(),
            label: label,
            value: label,
          }
        })
        // console.log(newDropOffDateOptions, resDataData)
        setHasError(false);
        setDropOffDateOptions(newDropOffDateOptions)
      })
      .finally(() => setLoading(false))
  }, 300)
  useEffect(() => {
    if (!pickUpLocationData || !dropOffLocationData || !pickUpDate) {
      return
    }
    setDropOffDateOptions([]);
    debounceGetUberFreight();
  }, [pickUpLocationData, dropOffLocationData, pickUpDate, vehicleType]);

  useEffect(() => {
    setDropOffDate(dropOffDateOptions[0]?.value)
  }, [dropOffDateOptions]);

  return (
    <div>
      <Modal
        open={options.isShow}
        footer={null}
        maskClosable={false}
        onCancel={options.onModalCancel}
        className={rootClassName}
      >
        <Spin spinning={loading} size="large">
        <div className={'get-an-instant-code-fba-container'}>
          <div className={'get-an-instant-code-fba-left-container'}>
            {/* <h1 className='getAnInstantCodeOTR-title'>{t('instant_otr_quote')}</h1>
            <p className='getAnInstantCodeOTR-text'>{t('instant_otr_rates')}</p> */}
            <div className={'get-an-instant-code-fba-left-img-container'}>
              <img src={fbaDetailImg} alt="" />
            </div>
          </div>
          <div className={'get-an-instant-code-fba-right-container'}>
            <div className="getAnInstantCode-vehicleType">
              <div className="getAnInstantCode-vehicleType-title">
                <h1>{t('instant_otr_vehicle_type')}</h1>
              </div>
              <div className="getAnInstantCode-vehicleType-btns">
                <Button
                    onClick={() => setVehicleType('DRY')}
                    className={vehicleType === 'DRY' ? 'getAnInstantCode-vehicleType-btn-2' : 'getAnInstantCode-vehicleType-btn-1'}>
                  {t('instant_otr_vehicle_type_dry')}
                </Button>
                <Button
                    onClick={() => setVehicleType('REFRIGERATED')}
                    className={vehicleType === 'REFRIGERATED' ? 'getAnInstantCode-vehicleType-btn-2' : 'getAnInstantCode-vehicleType-btn-1'}>
                  {t('instant_otr_vehicle_type_refrigerated')}
                </Button>
                <Button
                    onClick={() => setVehicleType('FLATBED')}
                    className={vehicleType === 'FLATBED' ? 'getAnInstantCode-vehicleType-btn-2' : 'getAnInstantCode-vehicleType-btn-1'}>
                  {t('instant_otr_vehicle_type_flatbed')}
                </Button>
              </div>
            </div>
            <div className='getAnInstantCodeOTR-from'>
              <div className='getAnInstantCodeOTR-from-left'>
                <div className='getAnInstantCodeOTR-from-left-div'>
                  <h1>{t('instant_dra_pick')}</h1>
                  <WGoogleMapsSelect onSelect={handlePickUpLocationSelect} />
                </div>
              </div>
              <div className='getAnInstantCodeOTR-from-left'>
                <div className='getAnInstantCodeOTR-from-left-div'>
                  <h1>{t('instant_dra_drop')}</h1>
                  <WGoogleMapsSelect onSelect={handleDropOffLocationSelect} />
                </div>
              </div>
              <div className='getAnInstantCodeOTR-from-right'>
                <div className='getAnInstantCodeOTR-from-right-div'>
                  <h1>{t('instant_otr_pick')}</h1>
                  <Select
                    size={'large'}
                    options={pickUpDateOptions}
                    value={pickUpDate}
                    onSelect={handlePickUpDateSelect}
                  />
                </div>
              </div>
              <div className='getAnInstantCodeOTR-from-right'>
                <div className='getAnInstantCodeOTR-from-right-div ant-col ant-form-item-control'>
                  <h1>{t('instant_otr_drop')}</h1>
                  <Select
                    size={'large'}
                    options={dropOffDateOptions}
                    value={dropOffDate}
                    onSelect={handleDropOffDateSelect}
                  />
                  <div id="dropOffDate_help" className="ant-form-item-explain ant-form-item-explain-connected css-dev-only-do-not-override-ed5zg0" role="alert">
                    <div className="ant-form-item-explain-error" style={{color: '#ff4d4f', height: '22px'}}>
                      {hasError && "Please select drop off date"}
                    </div>
                  </div>
                </div>  
              </div>
            </div>
            {/*<div className='getAnInstantCodeOTR-mar'>*/}
            {/*  <ul className='getAnInstantCodeOTR-mar-ul'>*/}
            {/*    {arrayMar.map((item,index) =>*/}
            {/*      <li key={'arrayMar'+index} className="getAnInstantCodeOTR-mar-ul-li">*/}
            {/*        <h1>{item.title}</h1>*/}
            {/*        <p style={{display:item.price?'':'none'}}>${item.price}</p>*/}
            {/*      </li>)}*/}
            {/*  </ul>*/}
            {/*</div>*/}
            {/*<p className='getAnInstantCodeOTR-leftText'>Shipment distance: 2,796 miles | Minimum transit time: 4 days 6 hours</p>*/}
            <div style={{ height: 30 }}></div>
            <Button className='getAnInstantCodeOTR-btn' onClick={() => goContactToBook()}>{t('instant_otr_back')}</Button>
            <Button className='getAnInstantCodeOTR-btn' type="text" onClick={() => cancel()}>{t('cancel')}</Button>
            </div>
          </div>
        </Spin>
      </Modal>
    </div>
  )
}
