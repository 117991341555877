import React from 'react'
import './index.scss'
import './mobile.scss'
import BlogArticlesItem from '../BlogArticlesItem'
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

export default function BlogArticles(props) {
  const options = Object.assign({}, defaultProps, props);
  const { t } = useTranslation()
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'blog-mobile-articles-container ' : 'blog-articles-container '
  const defaultProps = {
    title: t('more_articles'),
    data: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    btnText: '',
    onItemClick: () => { },
    onBtnClick: () => { },
    className: '',
  };
  const renderMoreArticlesItem = (item, index) => (
    <BlogArticlesItem
      onClick={options.onItemClick}
      item={item}
      index={index}
      className={'blog-articles-list-item-container'}
      key={'blog_articles_list_item_' + index}
    />
  )

  return (
    <div className={rootClassName + options.className}>
      <div className={'blog-articles-title'}>
        {options.title}
      </div>
      <div className={'blog-articles-list-container'}>
        {options.data.map(renderMoreArticlesItem)}
      </div>
      {
        options.btnText &&
        <div className={'blog-articles-btn-container'}>
          <div className={'blog-articles-btn'} onClick={options.onBtnClick}>
            {options.btnText}
          </div>
        </div>
      }
    </div>
  )
}
