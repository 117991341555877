import dayjs from 'dayjs';

import {DATE_FORMAT_TEMPLATE} from '@/constants';

/**
 * 或是是否是手机上显示（手机网页、手机APP）
 * @returns {boolean}
 */
export function getIsMobile () {
  const userAgent = navigator.userAgent
  return /mobile/i.test(userAgent)
}

const getTrimStr = (str) => typeof str === 'string' ? str.trim() : '';
export const formatGMapsAddress = function (address) {
  const [country, stateAndZipcode, city] = address.split(',').reverse()
  const [state, zipcode] = getTrimStr(stateAndZipcode).split(' ')
  return {
    address: address,
    postcode: getTrimStr(zipcode),
    city: getTrimStr(city),
    state: getTrimStr(state),
    country: getTrimStr(country),
  }
}

/**
 * 获取制定日期及之后六天的所有日期，共七天
 * @param excludesDays {number[]}
 * @param delayDays {number}
 * @returns {*[]}
 */
export function getTodayAfter7Days(excludesDays = [], delayDays = 0) {
  const result = [];
  let i = 0;
  const startDay = dayjs();
  while (result.length < 7) {
    const next = i++;
    const nextDay = startDay.add(next, 'day');
    if (!excludesDays.includes(nextDay.day())) {
      if (delayDays-- < 0) {
        result.push(startDay.add(next, 'day').format(DATE_FORMAT_TEMPLATE));
      }
    }
  }
  return result
}

// 获取去重后的数据
export const getDeDuplicationData = list => {
  let obj = {}
  const result = []
  list.forEach(item => {
    if (obj[item.label]) {
      return
    }
    obj[item.label] = true
    result.push(item)
  })
  obj = null
  return result
}

export const getAddDaysDate = (num = 0) => {
  const today = dayjs();
  return today.add(num, 'day').format(DATE_FORMAT_TEMPLATE)
}
