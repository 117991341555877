import axios from 'axios'
import {message} from 'antd'

const BASE_URL= 'https://wakool-scm.truckxi.com'
const instance = axios.create({
    baseURL: BASE_URL,
})
//请求拦截器
instance.interceptors.request.use((config) => {
    const tokenWhiteList = {
        // [API_REGISTER_URL]: ['post'],
    }
    const userData = JSON.parse(localStorage.getItem('userData'));
    const wakoolLanguage = localStorage.getItem('wakool_language_value') 
    config.params = {
      ...config.params,
      language: wakoolLanguage,
    }
    //白名单外的url和method才会发送token
    if (Object.keys(tokenWhiteList).some(url => config.url.includes(url) && tokenWhiteList[url].includes(config.method.toLowerCase())) === false) {
        //todo 是否要补充当没有token触发login的逻辑
    }
    // console.log(JSON.stringify(config));
    return config;
}, error => { return Promise.reject(error); }
);
// 响应拦截器
instance.interceptors.response.use(
    response => {
        //todo 是否判断后端返回401触发login的逻辑
        return response;
    }, error => {
      const {message: errMessage, detail} = error.response.data || {};
      const errMsg = errMessage || detail;
      if (errMsg) {
        message.error(errMsg)
        return Promise.reject(error.response.data)
      }
    //提示的错误信息
        return Promise.reject(error.response.status);
    }
);

export const getAnInstantQuoteByApi = function (data) {
  return instance({
    url: '/api/v1/wakool/wakool_get/',
    data,
    method: 'post',
  })
}
export const getZipcodeSearch = (data) => {
  return instance({
    url: '/api/v1/wakool/google_zipcode_search/',
    params: {
      search: data
    },
    method: 'get',
  })
}
export const getUberFreight = function (data) {
  return instance({
    url: '/api/v1/wakool/uberfreight/',
    data,
    method: 'post',
  })
}
export const sendFeedback = function (data) {
  return instance({
    url: '/api/v1/wakool/sendemail/',
    data,
    method: 'post',
  })
}
export const getFbaOrWalmartFile = (type, language) => {
  return instance({
    url: `/api/v1/wakool/fbaorwla_get/`,
    params: {
      pickup_address: type,
      language: language
    },
    method: 'get'
  })
}
export default {
    //接口返回
    //登录
    demo(object, config = {}) {
        return instance.post('https://www.uber.com/freight/shipper/instant-freight-quote/graphql', object, config);
    }
}
