import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom'
import Home from './pages/Home'
import Contact from './pages/Contact'
import Blog from './pages/Blog'
import BlogDetail from './pages/BlogDetail'
import Headers from '@/components/Headers'
import Footers from '@/components/Footers'
import Preview from './pages/Preview'
import './index.scss'
import { useSelector } from "react-redux";

function App() {
  const commonState = useSelector(state => state.common)
  return (
    <BrowserRouter>
      <div className="app" style={{ minWidth: commonState.isMobile ? '600px' : '1440px'}}>
        <Headers />
        {/* 路由都写在此处 */}
        <Routes>
          <Route path="/home" element={<Home/>}></Route>
          <Route path="/contact" element={<Contact/>}></Route>
          <Route path="/blog" element={<Blog/>}></Route>
          <Route path="/blogDetail" element={<BlogDetail/>}></Route>
          <Route path="*" element={<Navigate to={'/home'} replace={true} />}></Route>
          <Route path="/preview" element={<Preview/>}></Route>
        </Routes>
        <Footers />
      </div>
    </BrowserRouter>
  );
}

export default App;
