import React, { useRef, useState } from 'react';
import { Select } from 'antd';

import './index.scss'
import './mobile.scss';
import GoogleMaps from '@/components/GoogleMaps';
import { formatGMapsAddress, getDeDuplicationData } from '@/common';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";

export default function WGoogleMapsSelect(props) {
  const googleMapsRef = useRef(null)
  const [locationOptions, setLocationOptions] = useState([]);
  const [location, setLocation] = useState(undefined)
  const { t } = useTranslation();
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'w-google-maps-select-mobile-container' : 'w-google-maps-select-container'

  const handlePickUpLocationSearch = (newValue) => {
    const googleMapsCurrent = googleMapsRef?.current?.wrapperRef?.current
    googleMapsCurrent?.getSuggestionsListByText(newValue, data => {
      setLocationOptions(getDeDuplicationData(data));
    })
  };

  const handlePickUpLocationChange = (value) => {
    setLocation(value);
    props.onSelect(formatGMapsAddress(value));
  }

  return <div className={rootClassName}>
    <Select
      className={'w-google-maps-select-select'}
      showSearch
      value={location}
      placeholder={t('instant_dra_zip')}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      notFoundContent={null}
      onSearch={handlePickUpLocationSearch}
      onChange={handlePickUpLocationChange}
      options={locationOptions}
    />
    <GoogleMaps ref={googleMapsRef} />
  </div>
}
