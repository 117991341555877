import banner2cart from '@/assets/images/home/banner2cart.png';
import banner2map from '@/assets/images/home/banner2map.png';
import newMap from '@/assets/images/home/newMap.png';
import lineQrCodeImg from '@/assets/images/home/line-qr-code.png';
import wechatNewsQrCodeImg from '@/assets/images/home/wechat-news-qr-code.jpg';
import wechatQrCodeImg from '@/assets/images/home/wechat-qr-code.png';
import whatsAppQrCodeImg from '@/assets/images/home/whats-app-qr-code.png';
import wechatOfficialAccount from '@/assets/images/home/wechatOfficialAccount.png';
import wechatLogo from '@/assets/images/home/wechatLogo.png';
import linkLogo from '@/assets/images/home/lineLogo.png';
import whatsapp from '@/assets/images/home/whatsapp.png';
import facebook from '@/assets/images/home/facebook.png';
import ins from '@/assets/images/home/ins.png';
import linkedin from '@/assets/images/home/linkedin.png';
import redbook from '@/assets/images/home/redBook.png';
import eventBus from "@/common/EventBus";
import { HEADER_GET_AN_INSTANT_CODE_EVENT_NAME, FACEBOOK_LINK_URL, INSTAGRAM_LINK_URL, LINKED_IN_LINK_URL, XIAO_HONG_SHU_LINK_URL  } from '@/constants';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import './index.scss';
import './mobile.scss';
import FooterLogoList from '@/components/FooterLogoList'

export default function Index() {
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'home-mobile-container' : 'home-container'
  const { t } = useTranslation();
  const qrCodeInfoList = [
    {
      title: t('wechat_news'),
      img: wechatNewsQrCodeImg,
    },
    {
      title: t('wechat'),
      img: wechatQrCodeImg,
    },
    {
      title: t('whats_app'),
      img: whatsAppQrCodeImg,
    },
    {
      title: t('line'),
      img: lineQrCodeImg,
    }
  ]
  const iconInfoList = [
    {
      title: t('wechat_official_account'),
      img: wechatOfficialAccount,
      qrcodeImg: wechatNewsQrCodeImg
    },
    {
      title: t('wechat'),
      img: wechatLogo,
      qrcodeImg:  wechatQrCodeImg
    },
    {
      title: t('line_official_account'),
      img: linkLogo,
      qrcodeImg: lineQrCodeImg
    },
    {
      title: t('whats_app'),
      img: whatsapp,
      qrcodeImg: whatsAppQrCodeImg
    },
    {
      title: t('facebook'),
      img: facebook,
      path: FACEBOOK_LINK_URL
    },
    {
      title: t('instagram'),
      img: ins,
      path: INSTAGRAM_LINK_URL
    },
    {
      title: t('linkedin'),
      img: linkedin,
      path: LINKED_IN_LINK_URL
    },
    {
      title: t('redbook'),
      img: redbook,
      path: XIAO_HONG_SHU_LINK_URL
    }
  ]

  const showGetAnInstantCodeModal = () => {
    eventBus.dispatchEvent(HEADER_GET_AN_INSTANT_CODE_EVENT_NAME)
  }

  return (
    <div className={rootClassName}>
      {/* <div className={'home-top-container'}>
        {
          qrCodeInfoList.map((item, index) => (
            <div
              className={'home-top-item-container'}
              key={'qr-code-info-list-' + index}>
              <div className={'home-top-item-title'}>{item.title}</div>
              <div className={'home-top-item-img-container'}>
                <img src={item.img} alt="" />
              </div>
            </div>
          ))
        }
      </div> */}
      <div className="main-content" onClick={showGetAnInstantCodeModal}>
        <div className="nav-bar">
          <div className="nav-item active">
            {t('instant_port')}
          </div>
          <div className="nav-item">
            {t('instant_ftl')}
          </div>
          <div className="nav-item">
            {t('instant_ltl')}
          </div>
          <div className="nav-item">
            {t('instant_ware')}
          </div>
          <div className="nav-item">
            {t('instant_walmart_label')}
          </div>
        </div>
        <div className="quote-container">
          <div className="left">
            {/* <div className="title">
              {t('instant_otr_quote')}
            </div>
            <div className="description">
              {t('instant_dra_infomation')}
            </div>
            <div className="description">
              {t('instant_fba_thank')}
            </div> */}
            <div className="map">
              <img src={newMap} alt="" />
            </div>
          </div>
          <div className="right">
            <div className="pickup-container">
              <div className="title">
                {t('instant_dra_pick')}
              </div>
              <div className="location-container">
                <div className="location">
                  {t('home_los')}
                </div>
                <div className="location">
                  {t('home_sava')}
                </div>
                <div className="location">
                  {t('home_hous')}
                </div>
                <div className="location">
                  {t('home_jersey')}
                </div>
                <div className="location">
                  {t('home_angeles')}
                </div>
                <div className="location">
                  {t('home_rail')}
                </div>
              </div>
            </div>
            <div className="drop-off-container">
              <div className="title">
                {t('instant_dra_drop')}
              </div>
              <div className="input">
                <div className="icon"><img src={banner2cart} alt="" /></div>
                <div className="placeholder">{t('instant_dra_zip')}</div>
              </div>
            </div>
            <div className="quote-btn">
              <div className="btn-text">{t('instant_quote')}</div>
            </div>
          </div>
        </div>
      </div>
      <FooterLogoList />
    </div>
  )
}
